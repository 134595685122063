import AddReactionIcon from "@mui/icons-material/AddReaction";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import { useEffect } from "react";

const EmoteInputs = ({
  options = ["Excellent", "Good", "Medium", "Poor", "Bad"],
  handleChange,
  id = null,
}) => {
  const map = {
    Excellent: <AddReactionIcon color="primary" />,
    Good: <TagFacesIcon color="success" />,
    Medium: <SentimentSatisfiedIcon style={{ color: "orange" }} />,
    Poor: <SentimentVeryDissatisfiedIcon style={{ color: "#f008" }} />,
    Bad: <MoodBadIcon style={{ color: "red" }} />,
  };

  useEffect(() => {
    handleChange(null);
  }, []);

  return (
    <div className="emote-group-input">
      {options.map((op) => {
        return (
          <label key={op} htmlFor={id + op || op}>
            <input
              type="radio"
              name={id || "radio"}
              id={id + op || op}
              hidden
              onChange={() => handleChange(op.toLowerCase())}
            />
            {map[op]}
          </label>
        );
      })}
    </div>
  );
};

export default EmoteInputs;
