import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class DeviceConfigService {
  getDeviceConfig() {
    const option = {
      url: `${baseUrl}/deviceConfig`,
    };

    return axios.get(option);
  }

  createDeviceConfig(data) {
    const option = {
      url: `${baseUrl}/deviceConfig`,
      data,
    };

    return axios.post(option);
  }

  updateDeviceConfig(data) {
    const option = {
      url: `${baseUrl}/deviceConfig/${data.id}`,
      data,
    };

    return axios.put(option);
  }

  deleteDeviceConfig(id) {
    const option = {
      url: `${baseUrl}/deviceConfig/${id}`,
    };

    return axios.delete(option);
  }

  getDeviceConfigById(id) {
    const option = {
      url: `${baseUrl}/deviceConfig/${id}`,
    };

    return axios.get(option);
  }

  getDeviceConfigTable() {
    const option = {
      url: `${baseUrl}/deviceConfigTable`,
    };

    return axios.get(option);
  }
}

const deviceService = new DeviceConfigService();
export default deviceService;
