import React from "react";

const Question = ({ number, question }) => {
  return (
    <h2 className="truss-question">
      <span>{number}</span>
      <span>{question}</span>
    </h2>
  );
};

export default Question;
