import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class ProviderService {
  getProviders() {
    const option = {
      url: `${baseUrl}/providers`,
    };

    return axios.get(option);
  }
  createProvider(data) {
    const option = {
      url: `${baseUrl}/provider`,
      data,
    };

    return axios.post(option);
  }

  deleteProvider(id) {
    const option = {
      url: `${baseUrl}/provider`,
      data: { id },
    };

    return axios.delete(option);
  }

  getProviderById(id) {
    const option = {
      url: `${baseUrl}/provider/${id}`,
    };

    return axios.get(option);
  }

  updateProvider(data) {
    const option = {
      url: `${baseUrl}/provider`,
      data,
    };

    return axios.put(option);
  }

  getProviderDataForFlow() {
    const option = {
      url: `${baseUrl}/providerdata`,
    };

    return axios.get(option);
  }

  postFirstProviderFlow(data, id) {
    const option = {
      url: `${baseUrl}/providerdata/${id}`,
      data,
    };

    return axios.post(option);
  }

  createProviderDailyLog(text, id, day) {
    const option = {
      url: `${baseUrl}/createProviderdailylog`,
      data: { text, id, day },
    };

    return axios.post(option);
  }
  getPatientsByDoctorId() {
    const option = {
      url: `${baseUrl}/patientsByDocId`,
    };

    return axios.get(option);
  }
}

const providerService = new ProviderService();
export default providerService;
