import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactPhoneInput from "react-phone-input-material-ui";
import {
  Grid,
  Typography,
  Snackbar,
  FormControl,
  TextField,
  Select,
  Chip,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Box,
} from "@mui/material";
import providerService from "src/services/providerService";
import { LoadingButton } from "@mui/lab";
import careProviderService from "src/services/careProvidersService";
import generateRandomPassword from "../helper/generatePassword";
import loginService from "src/services/loginService";

const CareProviderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const myProps = location.state;
  const [formData, setFormData] = useState({
    id: null,
    careId: null,
    name: "",
    mobile: "+1",
    email: "",
    password: "",
    patients: [],
    providers: [],
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [mobileHelperText, setMobileHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [patients, setPatients] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [providers, setProviders] = useState([]);
  const [providerList, setProviderLists] = useState([]);
  const userType = loginService.getUserType();
  //passwordHelperText

  const isValidEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  // eslint-disable-next-line no-useless-escape

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleDropDown = (e) => {
  //   console.log(e);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const checkPassword = (str) => {
    const reg =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+[\]{}|;:',.<>?])[A-Za-z!@#$%^&*()_+[\]{}|;:',.<>?0-9]{8,16}$/;
    console.log("text", reg.test(str));
    return reg.test(str);
  };
  const getCareProviderById = async (id) => {
    try {
      console.log("id", id);
      const data = await careProviderService.getCareProviderById(id);
      console.log("data", data);
      setFormData(data);
      const providers = await providerService.getProviders();
      console.log("providers", providers);
      setProviders(providers);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPatientByDoctorId();
    if (myProps?.id) {
      getCareProviderById(myProps.id);
    }
  }, [myProps]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setEmailHelperText("");
    setMobileHelperText("");
    setPasswordHelperText("");
    console.log("event", event?.target?.name);
    // console.log(event);
    if (event?.target?.name && event?.target?.name !== "password") {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } else if (event?.target?.name === "password") {
      const condition = checkPassword(event.target.value);
      if (condition === false) {
        setPasswordHelperText(
          "Password must between 8-16 characters long\n contains a number\n an uppercase letter,\n a lowercase letter\n a special character"
        );
      }
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } else {
      setFormData({ ...formData, mobile: event });
    }
  };

  const handleMultiChange = (event) => {
    console.log("event", event);
    const {
      target: { value },
    } = event;
    setPatientList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFormData({
      ...formData,
      patients: event.target.value,
    });
  };

  const handleMultiChangeProvider = (event) => {
    console.log("event", event);
    const {
      target: { value },
    } = event;
    setProviderLists(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFormData({
      ...formData,
      providers: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    setLoading(true);
    console.log(formData);
    try {
      if (formData?.email && !isValidEmail(formData?.email)) {
        setEmailHelperText("Invalid Email");
        setDisable(false);
        setLoading(false);
        return;
      } else if (formData?.mobile?.length < 10) {
        setMobileHelperText("Invalid Mobile");
        setDisable(false);
        setLoading(false);
        return;
      } else if (!myProps?.id && checkPassword(formData?.password) === false) {
        setPasswordHelperText(
          "Password must be 8 characters long\n contain a number\n an uppercase letter,\n a lowercase letter\n a special character"
        );
        setDisable(false);
        setLoading(false);
        return;
      }
      if (myProps?.id) {
        await careProviderService.updateCare(formData);
      } else {
        await careProviderService.createCare(formData);
      }
      navigate("/care-providers/list", { replace: true });
      setMsg("Care Provider added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      setLoading(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  const handleBack = () => {
    navigate("/care-providers/list", { replace: true });
  };

  const getPatientByDoctorId = async () => {
    try {
      const data = await providerService.getPatientsByDoctorId();
      console.log("data", data);
      setPatients(data);
      const providers = await providerService.getProviders();
      console.log("providers", providers);
      setProviders(providers);
    } catch (err) {
      console.log(err);
    }
  };

  const generatePassword = () => {
    const password = generateRandomPassword();
    setFormData({ ...formData, password: password });
  };

  return (
    <>
      <Helmet>
        <title> Add Care Provider | Truss Health </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Care Provider
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MuiTelInput value={formData.mobile} onChange={handleChange} /> */}
              <ReactPhoneInput
                error={mobileHelperText ? true : false}
                value={formData.mobile}
                defaultCountry={"gb"}
                onChange={handleChange}
                // inputClass={classes.field}
                // dropdownClass={classes.countryList}
                component={TextField}
                helperText={mobileHelperText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                error={emailHelperText ? true : false}
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                helperText={emailHelperText}
              />
            </Grid>

            {myProps?.id ? (
              <>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required={myProps?.id ? false : true}
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    disabled={myProps?.id ? true : false}
                    // helperText={passwordHelperText}
                  />
                  {/* <a onClick={generatePassword}>Generate Password</a> */}
                </Grid>
                <Grid className="gen_password" item xs={12} sm={6}>
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    onClick={generatePassword}
                    //onClick={handleClick}
                  >
                    Generate Password
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6}>
                <TextField
                  required={myProps?.id ? false : true}
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  hidden={myProps?.id ? true : false}
                  helperText={passwordHelperText}
                />
              </Grid>
            )}
            {userType === "admin" ? (
              <></>
            ) : (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="dropdown-label">Patient</InputLabel>
                  <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple
                    name="patients"
                    value={formData.patients}
                    onChange={handleMultiChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={patients?.find((e) => e.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {patients.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.id}
                        name={name.name}
                        style={getStyles(name.name, formData.patients, theme)}
                      >
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {userType === "admin" ? (
              <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="dropdown-label">Provider</InputLabel>
                  <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip-provider"
                    multiple
                    name="provider"
                    value={formData.providers}
                    onChange={handleMultiChangeProvider}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={providers?.find((e) => e.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {providers.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.id}
                        name={name.name}
                        style={getStyles(name.name, formData.providers, theme)}
                      >
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6}></Grid>
            )}

            {myProps?.id ? (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Update
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              // <Grid className="submit_button" item xs={12} sm={6}>
              //   <Button
              //     disabled={disable}
              //     variant="contained"
              //     color="primary"
              //     type="submit"
              //   >
              //     Update
              //   </Button>
              // </Grid>
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
              // <Grid className="submit_button" item xs={12} sm={6}>
              //   <Button
              //     disabled={disable}
              //     variant="contained"
              //     color="primary"
              //     type="submit"
              //   >
              //     Submit
              //   </Button>
              // </Grid>
            )}
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default CareProviderForm;
