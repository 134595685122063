import React from 'react'

const CareProvider = () => {
  return (
    <div>
      {/* 
      
      You can change this component completely to be 
      an entry point for different flows.

      If their isn't already.
      
      */}
      CareProvider 
    </div>
  )
}

export default CareProvider
