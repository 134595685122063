import React, { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import ImageUploader from "src/components/imageUploader/ImageUploader";
// import TrussLogo from "src/components/truss-logo/TrussLogo";
import patientService from "src/services/patientService";

const UploadPhoto = () => {
  const [patient, setPatient] = useState();
  const [topImage, setTopImage] = useState(false);
  const [sideImage, setSideImage] = useState(false);
  const [thermalImage, setThermalImage] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [dailyLogData, setDailyLogData] = useState({});

  useEffect(() => {
    fetchPatient();
  }, []);

  const fetchPatient = async () => {
    const patient = await patientService.getPatientDataForFlow();
    console.log("patient", patient);
    setPatient(patient);
  };

  const handleImageTopUpload = async (image) => {
    try {
      const data = {
        topAngleImage: image,
        patientId: patient?.id,
        day: patient?.daysFromSurgery,
        patientDailyLogDataId: dailyLogData?.id,
      };
      let imgs = await patientService.uploadImage(data);
      imgs = imgs?.data?.data;
      console.log(imgs);
      setDailyLogData({...imgs});
      setTopImage(true);
    } catch (error) {
      setMsg("Image upload failed! Please try again");
      setOpen(true);
      console.error("Error uploading image:", error);
    }
  };

  const handleImageSideUpload = async (image) => {
    try {
      const data = {
        sideAngleImage: image,
        patientId: patient?.id,
        day: patient?.daysFromSurgery,
        patientDailyLogDataId: dailyLogData?.id,
      };
      let imgs = await patientService.uploadImage(data);
      imgs = imgs?.data?.data;
      console.log(imgs);
      setDailyLogData({...imgs});
      setSideImage(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setMsg("Image upload failed! Please try again");
      setOpen(true);
    }
  };

  const handleThermalImageUpload = async (image) => {
    try {
      const data = {
        thermalImage: image,
        patientId: patient?.id,
        day: patient?.daysFromSurgery,
        patientDailyLogDataId: dailyLogData?.id,
      };
      let imgs = await patientService.uploadImage(data);
      imgs = imgs?.data?.data;
      console.log(imgs);
      setDailyLogData({...imgs});
      setThermalImage(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setMsg("Image upload failed! Please try again");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="photo">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <h2>Follow the instructions below:</h2>

      <div className="uploader">
        {topImage ? (
          <>
            <div className="image-uploader">
              Top Image uploaded successfully
            </div>
          </>
        ) : (
          <ImageUploader
            headtext={"Angle 1 - Top"}
            text={
              "Please keep your best to keep your knee cap within the blue line"
            }
            onImageUpload={handleImageTopUpload}
          />
        )}
        {sideImage ? (
          <>
            <div className="image-uploader">
              Side Image uploaded successfully
            </div>
          </>
        ) : (
          <ImageUploader
            headtext={"Angle 2 - Side"}
            text={
              "Please keep your best to align your leg within the blue line"
            }
            onImageUpload={handleImageSideUpload}
          />
        )}
        {thermalImage ? (
          <>
            <div className="image-uploader">
              Side Image uploaded successfully
            </div>
          </>
        ) : (
          <ImageUploader
            headtext={"Thermal Image"}
            text={
              "Please keep your best to align your leg within the blue line"
            }
            onImageUpload={handleThermalImageUpload}
          />
        )
        }
      </div>
    </div>
  );
};

export default UploadPhoto;
