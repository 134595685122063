import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background: #0002;
  padding: 50px;
  position: absolute;
  bottom: 0;
`;
const Button = styled.button`
  outline: none;
  color: white;
  filter: invert(100%);
`;
const CloseButton = styled(Button)`
  background: url("https://img.icons8.com/ios-glyphs/30/delete-sign--v1.png");
  background-position: center;
  background-size: 30px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;

  position: absolute;
  top: 2rem;
  left: 1rem;
`;
const TakePhotoButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/000000/compact-camera.png");
  background-position: center;
  background-size: 30px;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  border: solid 4px black;
  border-radius: 50%;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const ConfirmPhotoButtom = styled(Button)`
  background: url("https://img.icons8.com/glyph-neue/60/000000/checkmark.png");
  background-position: center;
  background-size: 30px;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  border: solid 4px black;
  border-radius: 50%;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 30px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
`;
const ImagePreview = styled.div`
  width: 100%;
  height: 100%;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CameraComponent = ({ closeSelf, selectImage }) => {
  const [image, setImage] = useState(null);
  const [faceUser, setFaceUser] = useState(true);

  const cameraRef = useRef(null);
  const confirmPhoto = () => image && selectImage(image);

  function startCamera() {
    if (cameraRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: true,
          video: { facingMode: faceUser ? "user" : "environment" },
        })
        .then((stream) => {
          cameraRef.current.srcObject = stream;
          cameraRef.current.play();
        })
        .catch((err) => console.log(err));
    }
  }

  function switchCamera() {
    if (!cameraRef.current.srcObject || image) return;

    cameraRef.current.srcObject?.getTracks().forEach((track) => track.stop());

    setFaceUser(!faceUser);
  }

  function takePhoto() {
    if (cameraRef.current) {

      const canvas = document.createElement("canvas");
      canvas.width = cameraRef.current.videoWidth;
      canvas.height = cameraRef.current.videoHeight;

      canvas.getContext("2d").drawImage(cameraRef.current, 0, 0);

      const photo = canvas.toDataURL("image/jpeg");
      setImage(photo);

      cameraRef.current.srcObject?.getTracks().forEach((track) => track.stop());
    }
  }

  function closeCamera(){
    cameraRef?.current?.srcObject?.getTracks().forEach((track) => track.stop());
    selectImage(null)
    closeSelf();
  }

  useEffect(() => {
    startCamera();
  }, [image, faceUser]);

  useEffect(() => {
    return () => {
      setImage(null);
    };
  }, []);

  return (
    <Wrapper>
      <CloseButton onClick={closeCamera} />
      {image ? <ImagePreview image={image} /> : <video ref={cameraRef} muted />}
      <Control>
        {image ? (
          <button
            onClick={() => {
              setImage(null);
            }}
            style={{
              backgroundColor: "transparent",
              fontWeight: 700,
              color: "#fff",
            }}
          >
            Retake
          </button>
        ) : (
          <div></div>
        )}
        {image ? (
          <ConfirmPhotoButtom onClick={confirmPhoto} />
        ) : (
          <TakePhotoButton
            onClick={() => {
              if (cameraRef.current) takePhoto();
            }}
          />
        )}
        <ChangeFacingCameraButton
          onClick={() => {
            if (cameraRef.current) switchCamera();
          }}
        />
      </Control>
    </Wrapper>
  );
};

export default CameraComponent;
