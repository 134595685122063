import EmoteInputs from "./input-types/EmoteInputs";
import MultiSelect from "./input-types/MultiSelect";
import TextAreaInput from "./input-types/TextAreaInput";
import TextInput from "./input-types/TextInput";
import YesNo from "./input-types/YesNo";

const UserInput = ({
  type = "calendar",
  number,
  questionData,
  setUserAnswers,
  options = [],
}) => {
  const userInputType = {
    "multi-select": <MultiSelect options={options} handleChange={handleChange} msq={true}  />, // msq : multiple select ques
    "single-select": <MultiSelect options={options} handleChange={handleChange} msq={false}  />,
    emotes: <EmoteInputs options={options} handleChange={handleChange}/>,
    text: <TextInput handleChange={handleChange} />,
    "yes-no": <YesNo  handleChange={handleChange} />,
    'textarea':<TextAreaInput handleChange={handleChange} />
  };

  function handleChange(data) {
    questionData.answer = data || null;

    setUserAnswers((prev) => {
      prev[number] = questionData;
      return prev;
    });
  }

  return <div className="truss-user-input">{userInputType[type]}</div>;
};

export default UserInput;
