import React from "react";

const RadioGroup = ({ options, handleChange }) => {
  return (
    <div className="radio-group-input">
      {options.map((op) => {
        return (
          <label key={op} htmlFor={op}>
            <input
              type="radio"
              name="radio"
              id={op}
              onChange={() => handleChange(op.toLowerCase())}
            />
            {op}
          </label>
        );
      })}
    </div>
  );
};

export default RadioGroup;
