import React from "react";
import TrussLogo from "src/components/truss-logo/TrussLogo";
import "./common.css";
const SurgeryDay = () => {
  // const text = `Make sure you have the bag you packed with everything you need.

  // Take a shower with any special instructions from your physician.\n

  // Take any PREAPPROVED medications with a sip of water.\n

  // Do not wear makeup, jewelry, contacts or lotions.\n

  // Make sure your cellphone is fully charged.`;
  return (
    <div className="guidance-container">
      <TrussLogo />

      <div className="content">
        <h1 className="title titleDay">Day of Surgery</h1>
        <h2 className="subtitle spaceTittle">Ready to go</h2>
        <div className="pointers">
          <ul>
            <li className="spaceText">
              Make sure you have the bag you packed with everything you need.
            </li>
            <li className="spaceText">
              Take a shower with any special instructions from your physician.
            </li>
            <li className="spaceText">
              Take any PREAPPROVED medications with a sip of water.
            </li>
            <li className="spaceText">
              Do not wear makeup, jewelry, contacts, or lotions.
            </li>
            <li className="spaceText">
              Make sure your cellphone is fully charged.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SurgeryDay;
