import Cookies from "universal-cookie";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";
import axios from "axios";

const axios1 = new Axios();
const baseUrl = BASEURL;
const cookies = new Cookies();

class UserService {
  getUsers() {
    const option = {
      url: `${baseUrl}/admin/users`,
    };

    return axios1.get(option);
  }

  createUser(data) {
    const option = {
      url: `${baseUrl}/admin/user`,
      data,
    };

    return axios1.post(option);
  }

  deleteUser(id) {
    const option = {
      url: `${baseUrl}/admin/user/${id}`,
    };

    return axios1.delete(option);
  }

  uploadFile(formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/admin/fileUpload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }); axios
    //     .post(`${baseUrl}/admin/fileUpload`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
    //       },
    //     })
    //     .then((response) => {
    //       // Handle success response here.
    //       console.log("File uploaded successfully!", response);
    //       return response;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       return error;
    //     });
  }
}

const userService = new UserService();
export default userService;
