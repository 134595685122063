import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmoteInputs from "src/components/doctor-form/components/input-types/EmoteInputs";
import TrussLogo from "src/components/truss-logo/TrussLogo";
import patientService from "src/services/patientService";

const KneeQs = () => {
  const navigate = useNavigate();
  const [mainQ, setMainQ] = useState({
    q: "How severe is your knee stiffing after first walking in the morning?",
    a: null,
  });
  const [skipped, setSkipped] = useState(true);

  const [followups, setFollowups] = useState([
    { q: "Twisting/pivoting on your knee", a: 0 },
    { q: "Straightening Knee fully ", a: 0 },
    { q: "Going up or down stairs", a: 0 },
    { q: "Standing upright", a: 0 },
    { q: "Rising from Sitting", a: 0 },
  ]);

  function handleIputChange(index, emoteVal) {
    let temp = [...followups];
    temp[index].a = emoteVal.target.value;

    setFollowups(temp);
    setSkipped(false);
  }

  function mainQsetter(data) {
    setMainQ({ ...mainQ, a: data });
    setSkipped(false);
  }

  async function onSubmit() {
    console.log("mainQ", mainQ);
    console.log("followups", followups);
    try {
      if (mainQ || followups) {
        await patientService.addKneeQs(mainQ, followups);
      }
    } catch (err) {
      console.log(err);
    }

    navigate("/guidance", {
      state: {
        condition: "preSurgery",
      },
      replace: true,
    });
    // navigate("/guidance", {
    //   state: {
    //     condition: "dayBeforeSurgery",
    //   },
    //   replace: true,
    // });

    // DO something
  }

  return (
    <div className="kneeQ-container">
      <TrussLogo />

      <div className="content">
        <div className="main-ques">
          <h2>{mainQ.q}</h2>
          <div>
            <EmoteInputs handleChange={mainQsetter} id={"main"} />
          </div>
        </div>

        <div className="followups">
          <h3>
            What amount of knee pain you experienced in the last week during the
            following activities
          </h3>
          <div className="questions">
            {followups.map(({ q: ques }, i) => {
              return (
                <div key={i + ques} className="question">
                  <p className="ques">
                    {i + 1}. {ques}
                  </p>
                  <input
                    type="range"
                    step={1}
                    min={1}
                    max={10}
                    defaultValue={0}
                    onChange={(e) => handleIputChange(i, e)}
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    // id={"sub" + (i + 1)}
                  />
                  <p>
                    <span style={{marginLeft: "1%"}}>1</span>
                    <span style={{marginLeft: "8%"}}>2</span>
                    <span style={{marginLeft: "8%"}}>3</span>
                    <span style={{marginLeft: "8%"}}>4</span>
                    <span style={{marginLeft: "8%"}}>5</span>
                    <span style={{marginLeft: "8%"}}>6</span>
                    <span style={{marginLeft: "8%"}}>7</span>
                    <span style={{marginLeft: "8%"}}>8</span>
                    <span style={{marginLeft: "8%"}}>9</span>
                    <span style={{marginLeft: "8%"}}>10</span>
                  </p>
                </div>
                // <div key={i + ques}>
                //   <h4>
                //     {i + 1}. {ques}
                //   </h4>
                //   <div>
                //     <EmoteInputs
                //       handleChange={(data) => handleIputChange(i, data)}
                //       id={"sub" + (i + 1)}
                //     />
                //   </div>
                // </div>
              );
            })}
          </div>
        </div>

        <button onClick={onSubmit}>{skipped ? "Skip" : "Submit"}</button>
      </div>
    </div>
  );
};

export default KneeQs;
