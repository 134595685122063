import TrussLogo from "src/components/truss-logo/TrussLogo";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import Fab from "@mui/material/Fab";
import "./common.css";
import loginService from "src/services/loginService";
import { useNavigate } from "react-router-dom";

const StyledRoot = styled("div")({
  display: "flex",
  overflow: "hidden",
  background: "white",
});

export default function MobileLayout(userType) {
  const navigate = useNavigate();

  const doLogout = () => {
    loginService.dologout();
    console.log(userType);
    if (userType?.userType === "careprovider") {
      navigate("/careLogin", { replace: true });
    } else {
      navigate("/patientLogin", { replace: true });
    }
  };

  return (
    <StyledRoot className="main-div-phone">
      <div className="truss-logo">
        <TrussLogo />
      </div>
      <div className="logout-buton">
        <Fab size="medium" color="error" aria-label="logout" onClick={doLogout}>
          <LogoutIcon />
        </Fab>
      </div>
    </StyledRoot>
  );
}
