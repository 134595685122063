import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
//import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import patientService from "../services/patientService.js";
import loginService from "src/services/loginService";
import displayService from "src/services/displayService";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// mock
//import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "providerName", label: "Doctor Name", alignRight: false },
  { id: "flowType", label: "Flow Type", alignRight: false },
  { id: "flowName", label: "Flow Name", alignRight: false },
  { id: "sequence", label: "Sequence", alignRight: false },
  { id: "active", label: "Active", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DisplayConfigPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [configData, setConfig] = useState([]);
  const [apiload, setApiLoad] = useState(true);
  const userType = loginService.getUserType();
  useEffect(() => {
    getDisplayConfig();
  }, []);

  const getDisplayConfig = async () => {
    try {
      let configData = await displayService.getDisplayConfig();
      console.log("configData", configData);
      setConfig(configData);
      setApiLoad(false);
    } catch (err) {
      console.log(err);
      setApiLoad(false);
      //navigate("/404", { replace: true });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = configData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEdit = (e, id) => {
    try {
      navigate("/flowConfig/edit", {
        state: {
          id,
        },
        replace: true,
      });
    } catch (err) {}
  };

  const handleDelete = async (e, id) => {
    try {
      setApiLoad(true);
      await displayService.deleteDisplayConfig(id);
      getDisplayConfig();
      setApiLoad(false);
    } catch (err) {
      console.log(err);
      setApiLoad(false);
    }
  };

  const handleResume = async (e, id) => {
    try {
      setApiLoad(true);
      const data = {
        id,
        active: false,
      };
      await displayService.updateDisplayConfig(data);

      getDisplayConfig();
      setApiLoad(false);
    } catch (err) {
      console.log(err);
      setApiLoad(false);
    }
  };

  const handlePause = async (e, id) => {
    try {
      setApiLoad(true);
      const data = {
        id,
        active: true,
      };
      await displayService.updateDisplayConfig(data);
      getDisplayConfig();
      setApiLoad(false);
    } catch (err) {
      console.log(err);
      setApiLoad(false);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - configData.length) : 0;

  const filteredUsers = applySortFilter(
    configData,
    getComparator(order, orderBy),
    filterName
  );
  const addNewConfig = () => {
    navigate("/flowConfig/add", { replace: true });
  };

  const isNotFound = !filteredUsers.length;

  return (
    <>
      <Helmet>
        <title> Display Configuration | Truss Health </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Display Configuration
          </Typography>
          {userType && userType === "admin" ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={addNewConfig}
            >
              New Display Config
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={configData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        providerName,
                        flowType,
                        flowName,
                        sequence,
                        active,
                      } = row;
                      const selectedUser = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatar} /> */}
                              <TableCell align="left">{id}</TableCell>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{providerName}</TableCell>
                          <TableCell align="left">{flowType}</TableCell>
                          <TableCell align="left">{flowName}</TableCell>
                          <TableCell align="left">{sequence}</TableCell>
                          <TableCell align="left">
                            {active?.toString()}
                          </TableCell>
                          <TableCell align="right" id={id}>
                            {/* <IconButton
                              id={id}
                              aria-label="edit"
                              color="success"
                              onClick={(e) => handleEdit(e, id)}
                            >
                              <EditIcon id={id} />
                            </IconButton> */}
                            {active ? (
                              <IconButton
                                id={id}
                                aria-label="resume"
                                color="error"
                                onClick={(e) => handleResume(e, id)}
                              >
                                <BlockIcon id={id} />
                              </IconButton>
                            ) : (
                              <IconButton
                                id={id}
                                aria-label="pause"
                                color="success"
                                onClick={(e) => handlePause(e, id)}
                              >
                                <PlayArrowIcon id={id} />
                              </IconButton>
                            )}
                            <IconButton
                              id={id}
                              aria-label="delete"
                              color="error"
                              onClick={(e) => handleDelete(e, id)}
                            >
                              <DeleteIcon id={id} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {apiload && (
                  <TableBody>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Skeleton animation={apiload ? "wave" : false} />
                      <Skeleton animation={apiload ? "wave" : false} />
                      <Skeleton animation={apiload ? "wave" : false} />
                      <Skeleton animation={apiload ? "wave" : false} />
                      <Skeleton animation={apiload ? "wave" : false} />
                    </TableCell>
                  </TableBody>
                )}

                {isNotFound && !apiload && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          {/* <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography> */}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={configData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
