/**
 *
 * All static components at one place.
 *
 */

import { useState, useEffect } from "react";
import Notes from "./subFlows/dailyLogs/ Notes";
import BloodTest from "./subFlows/dailyLogs/BloodTest";
import MentalHealth from "./subFlows/dailyLogs/MentalHealth";
import Pain from "./subFlows/dailyLogs/Pain";
import Symptoms from "./subFlows/dailyLogs/Symptoms";
import UploadPhoto from "./subFlows/dailyLogs/UploadPhoto";
import patientService from "src/services/patientService";
import displayService from "src/services/displayService";

/********************************************

                Pre-Surgery

*********************************************/

export const ExercisesContent = () => {
  return (
    <div>
      <h2 className="subtitle">
        Recommended exercises everyday 15 days before surgery
      </h2>
      <div className="pointers">
        <ul>
          <li>
            Chair Push up
            <img
              style={{ width: "50%" }}
              src={require("./icons/chair_pushup.png")}
              alt="Chair Push up"
            />
          </li>
          <li>
            Long Arc Quad
            <img
              style={{ width: "50%" }}
              src={require("./icons/long_arc.jpeg")}
              alt="Long Arc Quad"
            />
          </li>
          <li>
            Straight Legs Risers
            <img
              style={{ width: "50%" }}
              src={require("./icons/straight_legs.jpeg")}
              alt="Long Arc Quad"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
export const MedicationGuidelines = () => {
  return (
    <div>
      <div className="medication-text">
        <p>
          Before you come to the hospital for your surgery, you will need to
          provide a list of any medications you are on to your physician so they
          may evaluate them and take any necessary action if necessary.
        </p>
      </div>
    </div>
  );
};
export const EatingGuidelines = () => {
  return (
    <div className="pointers">
      <ul>
        <li>Eat a well-balanced diet with plenty of fiber (such as bran).</li>
        <li>NO SOLID FOOD AFTER MIDNIGHT BEFORE SURGERY, ONLY WATER.</li>
      </ul>
    </div>
  );
};
export const PreparingYourHomePre = () => {
  return (
    <div className="pointers">
      <ul>
        <li>Add handlebars in the shower and bathroom.</li>
        <li>Arrange to sleep on the ground floor to avoid stairs.</li>
        <li>Prepare to need aid from others to stand or move up stairs.</li>
      </ul>
    </div>
  );
};
export const PreventingComplications = () => {
  return (
    <div className="scroll">
      <h2 className="subtitle">Infection:</h2>
      <div className="pointers">
        <ul>
          <li>
            Hand washing (or an alcohol-based hand cleanser) is the most
            important step for preventing infection.
          </li>
          <li>Keep your incision clean and dry except for showering.</li>
          <li>
            Your orthopedic surgeon may want you to take an antibiotic pill
            before you undergo future dental procedures or other invasive
            medical procedures.
          </li>
          <li>
            Contact your surgeon if:
            <ul>
              <li>Increased redness, heat, or swelling around the incision.</li>
              <li>Increased or foul-smelling drainage from the incision.</li>
              <li>Increased pain in the joint.</li>
              <li>Persistent fever greater than 101.5°F or chills.</li>
            </ul>
          </li>
        </ul>
      </div>

      <h2 className="subtitle">Control your pain after surgery:</h2>
      <div className="pointers">
        <ul>
          <li>
            Do not wait until the pain is intense. The higher the pain level,
            the harder it is to get it under control.
          </li>
          <li>Reposition yourself.</li>
          <li>
            Frequent use of elevation and an ice pack can reduce pain and
            swelling.
          </li>
        </ul>
      </div>

      <h2 className="subtitle">Blood Clots in Leg:</h2>
      <div className="pointers">
        <p>Signs and symptoms include the following:</p>
        <ul>
          <li>Pain and/or tenderness in your calf.</li>
          <li>Warmth and/or redness in your calf.</li>
          <li>Swelling in your leg that is not relieved when elevated.</li>
        </ul>
      </div>

      <h2 className="subtitle">Pneumonia:</h2>
      <div className="pointers">
        <p>It is important that you move and take deep breaths.</p>
      </div>

      <h2 className="subtitle">Preparing for Surgery Mentally:</h2>
      <div className="pointers">
        <ul>
          <li>
            Negative thinking and catastrophizing are common after surgery.
          </li>
          <li>Often you think you'll never feel better again.</li>
          <li>
            Try to catch these dark thoughts and shift them toward encouraging,
            hopeful, or more reality-based content.
          </li>
          <li>
            Preserving a sense of positivity in the face of challenges has
            evidence-based benefits on the mind, body, and soul.
          </li>
        </ul>
      </div>
    </div>
  );
};
export const OtherTips = () => {
  return (
    <div className="pointers">
      <ol>
        <li>Stop Smoking/Tobacco use for at least 6 weeks.</li>
        <li>
          Do not drink any alcoholic beverages (beer, wine, or hard liquor)
          within 7 days of your surgery.
        </li>
        <li>
          If you develop any illness such as a cold, flu, temperature, skin
          rash, or infection, or “flare-up” of a health problem in the 10 days
          prior to your surgery, please notify your surgeon’s office
          immediately.
        </li>
        <li>
          Do not shave near the area where you will have surgery. Shaving with a
          razor can irritate your skin, making it easier to develop an
          infection.
        </li>
        <li>Avoid torquing or twisting motions of the knee.</li>
      </ol>
    </div>
  );
};

/********************************************
 
            Day Before Surgery

*********************************************/

export const WhatToBringToTheHospital = () => {
  return (
    <div className="pointers">
      <ul>
        <li>
          List of all medications and supplements you take, including dose and
          frequency.
        </li>
        <li>
          Lose slippers with non-skid soles and heel backs. Your feet will be
          swollen after surgery.
        </li>
        <li>Knee-length or short bathrobe.</li>
        <li>Toiletries such as a toothbrush, toothpaste, comb, brush, etc.</li>
        <li>
          Glasses, hearing aids with extra batteries and their containers.
        </li>
        <li>A container for dentures or partials.</li>
        <li>Comfortable clothing to wear home.</li>
        <li>Insurance card(s), photo identification.</li>
        <li>
          Co-payment (If your health plan coverage requires a co-payment for
          hospitalization or discharge medications, bring ONLY the amount of
          cash necessary, a check, or credit card to cover these expenses.)
        </li>
        <li>Pre-op Packet.</li>
        <li>Copy of your Advanced Directive if you have one.</li>
      </ul>
    </div>
  );
};
export const PrepareYourSelf = () => {
  return (
    <div className="pointers">
      <ul>
        <li>
          Do not eat or drink anything except water after midnight before the
          surgery.
        </li>
        <li>Follow any bathing instructions from your provider.</li>
        <li>Skin decontamination before surgery:</li>
        <li>- Betadine Shower (whole body)</li>
        <li>- Chlorhexidine wipes/shower</li>
        <li>Do not shave.</li>
        <li>Get 8 hours of sleep.</li>
      </ul>
    </div>
  );
};
export const PreparingYourHomeBefore = () => {
  return (
    <div className="pointers">
      <ul>
        <li>
          Household help: You may need help with groceries, meal preparation,
          and housekeeping for several weeks. Find friends and family or
          consider hiring someone to help you until you can care for yourself.
        </li>
        <li>
          Pathways: Move furniture and clutter to make a clear path to your
          kitchen, bathroom, and bedroom. The path should be wide enough for a
          walker or at least 26 inches wide.
        </li>
        <li>
          Trip hazards: Remove any loose rugs or other items you may trip over.
        </li>
        <li>Pet care: Arrange for the care of your pets as needed.</li>
        <li>
          Meals: Buy groceries and put cooking utensils within easy reach. Make
          and freeze simple meals that will be easy to prepare when you are
          home.
        </li>
        <li>
          Living areas: Place items you need every day within easy reach to
          avoid bending over or reaching too far overhead.
        </li>
        <li>Bathroom: Place a nonskid bathmat in your tub or shower.</li>
        <li>
          Furniture: You will need a stable, high-back chair with armrests. You
          should not sit on a chair that rocks, rolls, or swivels during your
          recovery from surgery.
        </li>
      </ul>
    </div>
  );
};
export const WhatToBuyForSurgery = () => {
  return (
    <div className="pointers">
      <ul>
        <li>
          Leg support elevation pillow -{" "}
          <a
            href="https://www.amazon.com/Purecomfortstoreon"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purecomfortstore on Amazon
          </a>
        </li>
        <li>
          Stationary bike -{" "}
          <a
            href="https://www.onepeloton.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Peloton
          </a>{" "}
          or Video game bike
        </li>
        <li>
          Ice machine or some type of ice pack that wraps around the knee -{" "}
          <a
            href="https://www.amazon.com/Reusable-Ice-Pack-Knee-Compression/dp/B072VQPG63"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon Link
          </a>
        </li>
        <li>
          Comfortable tennis shoes with some boost in them rather than flat
        </li>
        <li>Rails for the bathroom</li>
        <li>Compression socks</li>
        <li>
          Scar cream -{" "}
          <a
            href="https://www.amazon.com/Mederma-Advanced-Scar-Gel-Recommended/dp/B000052YOB"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon Link
          </a>
        </li>
        <li>Hiking pole/cane</li>
        <li>Bandages and alcohol just in case of an emergency</li>
        <li>Life alert (Depending on age)</li>
      </ul>
    </div>
  );
};

/********************************************
 
            Post Surgery

*********************************************/

export const DailyLog = ({ closeSelf }) => {
  const forms = [
    { title: "Pain", component: <Pain handleChange={handleChange} page={0} /> },
    {
      title: "Photo",
      component: <UploadPhoto handleChange={handleChange} page={1} />,
    },
    {
      title: "Body",
      component: <Symptoms handleChange={handleChange} page={2} />,
    },
    {
      title: "Mental Health",
      component: <MentalHealth handleChange={handleChange} page={3} />,
    },
    {
      title: "Blood Test",
      component: <BloodTest handleChange={handleChange} page={4} />,
    },
    {
      title: "Other",
      component: <Notes handleChange={handleChange} page={5} />,
    },
  ];

  const [stepsCompleted, setStepsCompleted] = useState(0);

  const initialLogs = [
    { title: "Pain", log: null },
    { title: "Photo", log: null },
    { title: "Body", log: null },
    { title: "Mental Health", log: null },
    { title: "Blood Test", log: null },
    { title: "Other", log: null },
  ];
  const [logs, setLogs] = useState(initialLogs);
  const [form, setForms] = useState([]);

  useEffect(() => {
    getQuestionSort();
  }, []);

  async function getQuestionSort() {
    try {
      const data = await displayService.getQuestionSort("Post-Procedure");
      console.log(data);
      if (data?.length) {
        const questionArr = [];
        const logArr = [];
        for (const quest of data) {
          const question = forms.find((q) => q?.title === quest?.flowName);
          const logData = initialLogs.find((q) => q?.title === quest?.flowName);
          if (question) {
            questionArr.push(question);
          }
          if (logData) {
            logArr.push(logData);
          }
        }
        console.log("questionArr", questionArr);
        console.log("logArr", logArr);
        setForms(questionArr);
        setLogs(logArr);
      } else {
        setForms(forms);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleChange(page, logData) {
    const temp = [...logs];
    temp[page].log = logData;

    setLogs(temp);
  }

  async function submitLogs() {
    if (stepsCompleted !== 5) return;
    console.log("logs", logs);
    try {
      await patientService.submitLogs(logs);
      setStepsCompleted(0);
      setLogs(initialLogs);
    } catch (e) {
      console.log(e);
    }

    closeSelf();
  }

  const prevStep = () => setStepsCompleted(Math.max(stepsCompleted - 1, 0));
  const nextStep = () =>
    setStepsCompleted(
      Math.min(stepsCompleted + 1, form?.length > 0 ? form.length - 1 : 5)
    );

  // console.log("prevStep", prevStep);
  // console.log("nextStep", nextStep);

  const currentStep = form[stepsCompleted];
  return (
    <>
      <div className="form-head">
        {stepsCompleted === 0 ? (
          <div></div>
        ) : (
          <button onClick={prevStep}>Back</button>
        )}
        {/* <button onClick={prevStep}>Back</button> */}
        <h3>{currentStep?.title}</h3>
        {stepsCompleted === (form.length -1) ? (
          <div></div>
        ) : (
          <button onClick={nextStep}>Next</button>
        )}
        {/* <button onClick={nextStep}>Next</button> */}
      </div>
      <div className="form-content">{currentStep?.component}</div>

      {stepsCompleted === 5 || (stepsCompleted === form.length -1) ? (
        <button onClick={submitLogs}>Submit Logs</button>
      ) : (
        <></>
      )}
    </>
  );
};

export const FAQ = () => {
  return (
    <div id="faqs" className="faqs">
      <h3>Frequently Asked Questions</h3>
      <ol>
        <li>
          <strong>How long does recovery generally take?</strong> For a healthy
          adult doing exercises regularly and following up with all post-surgery
          requirements, 3-4 months.
        </li>
        <li>
          <strong>When can I bathe?</strong> You may shower in the hospital, but
          when you arrive home you may shower yourself. You may be instructed to
          keep water away from the incision, however.
        </li>
        <li>
          <strong>How to tell if my scar is infected?</strong> Some swelling and
          discoloration is normal. However, painful red swelling, thick
          drainage, or high fevers may indicate infection and should be reported
          to a physician immediately.
        </li>
        <li>
          <strong>When can I drive again?</strong> In most cases, 4-6 weeks.
          However, you should not drive unless you get your physician's approval
          due to pain medications and possible recovery complications.
        </li>
        <li>
          <strong>How do I manage the pain?</strong> You may be put on pain
          medications. Ask a physician if pain medications do not work. Some
          pain is to be expected, so be patient.
        </li>
        <li>
          <strong>How long does surgery take?</strong> Usually between 60-90
          minutes.
        </li>
        <li>
          <strong>What are the anesthesia options for TKR?</strong> General
          anesthesia, Spinal or epidural, Regional nerve block anesthesia. Your
          physician will inform you on which form will be suitable for your
          needs.
        </li>
        <li>
          <strong>
            What should I generally expect daily life to be like post-surgery?
          </strong>
          Expect to need assistance doing everyday things like standing up,
          showering, or going to the bathroom. Expect some pain. Expect to need
          to put effort in, despite discomfort, to mobilize yourself at PT
          appointments or at home to help get back to normal. It takes a bit of
          dedication to heal and get back to normal.
        </li>
        <li>
          <strong>How long are TKR incisions usually?</strong> They are
          generally anywhere between 4-9 inches long.
        </li>
        <li>
          <strong>What is the difference between glue and staples?</strong>
          The glue proves to be the most cosmetically appealing, better
          post-operative pain tolerance, and fewer wound complications. However,
          your physician will likely choose whichever they think is best for
          you. Open it up to discussion with your physician if you prefer one or
          the other.
        </li>
        <li>
          <strong>Should I ice my scar?</strong> Yes, use a cold therapy
          machine, a real ice pack, or a pack of frozen veggies. Never apply the
          ice to bare skin, as it may cause frostbite. Apply it over a towel or
          some thin protective layer. Ask your physician for the best icing
          method for you as well as size or brand specifications.
        </li>
      </ol>
    </div>
  );
};
