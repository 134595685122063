// export const BASEURL = 'http://localhost:4000';
export const BASEURL = process.env.REACT_APP_BASE_URL;

export const PREPROCEDUREOPTIONS = [
  { name: "Patient surgery Date", id: 1, seq: 1 },
  { name: "Weight Question", id: 2, seq: 2 },
  { name: "Gender", id: 3, seq: 3 },
  { name: "Existing Conditions", id: 4, seq: 4 },
];

export const PREPROCEDUREOLDOPTIONS = [
  { name: "Welcome Back", id: 1, seq: 1 },
  { name: "Knee Pain", id: 2, seq: 2 },
];

export const SAMEDAYOPTIONS = [{ name: "Description", id: 1, seq: 1 }];

export const POSTPROCEDUREOPTIONS = [
  { name: "Pain", id: 1, seq: 1 },
  { name: "Photo", id: 2, seq: 2 },
  { name: "Body", id: 3, seq: 3 },
  { name: "Mental Health", id: 4, seq: 4 },
  { name: "Blood Test", id: 5, seq: 5 },
  { name: "Other", id: 6, seq: 6 },
];
