import React from "react";
import { useLocation } from "react-router-dom";
import TrussLogo from "src/components/truss-logo/TrussLogo";
import TipCard from "src/components/tip-card/TipCard";
import MobileLayout from "../layouts/dashboard/MobileLayout";

// Icons
import icon from "../components/tip-card/icons/hash.svg";
import exercise from "../components/tip-card/icons/exercise.svg";
import faq from "../components/tip-card/icons/faq.svg";
import tracker from "../components/tip-card/icons/tracker.svg";
import community from "../components/tip-card/icons/community.svg";
import msg from "../components/tip-card/icons/msg.svg";
import help from "../components/tip-card/icons/help.svg";
import log from "../components/tip-card/icons/log.svg";
import ride from "../components/tip-card/icons/ride.svg";
import bringHospital from "../components/tip-card/icons/bringHospital.svg";
import buy from "../components/tip-card/icons/buy.svg";
import prepare from "../components/tip-card/icons/prepare.svg";
import preventing from "../components/tip-card/icons/preventing.svg";
import tips from "../components/tip-card/icons/tips.svg";
import eatingGuide from "../components/tip-card/icons/eatingGuide.svg";
import hospitalGuide from "../components/tip-card/icons/hospitalGuide.svg";
import homePrep from "../components/tip-card/icons/homePrep.svg";

const Guidance = () => {
  const location = useLocation();
  const myProps = location.state;
  console.log("conditions", myProps?.condition);
  const tiles = [
    {
      icon: icon,
      title: "Exercises",
      bg: "#AFE0FFFF",
      color: "#333",
      content: "",
    },
    {
      icon: icon,
      title: "Eating Guidelines",
      bg: "#EDCDB4FF",
      color: "#333",
      content:
        "Eat a well-balanced diet with plenty of fiber(such as bran) NO SOLID FOOD AFTER MIDNIGHT BEFORE SURGERY, ONLY WATER",
    },
    {
      icon: icon,
      title: "Preventing Complications",
      bg: "#D7E9DBFF",
      color: "#333",
      content: "",
    },
    {
      icon: icon,
      title: "Medication Guidelines",
      bg: "#ECF0C8FF",
      color: "#333",
      content:
        "Before you come to the hospital for your surgery you will need Provide a list of any medications you are on to your physician so they may evaluate them and take any action if necessary",
    },
    {
      icon: icon,
      title: "Preparing Your Home",
      bg: "#C7E4DFFF",
      color: "#333",
      content: "",
    },
    {
      icon: icon,
      title: "What to Bring to the Hospital",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    { icon: icon, title: "Ride", bg: "lightgray", color: "#333", content: "" },
    {
      icon: icon,
      title: "What to buy to be ready for surgery",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: icon,
      title: "Prepare Your Self",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: icon,
      title: "Prepare Your Home",
      bg: "lightgray",
      color: "#333",
      content:
        "•Add handlebars in the shower and bathroom •Arrange to sleep on the ground floor to avoid stairs •Prepare to need aid from others to stand or move up stairs",
    },
    {
      icon: icon,
      title: "Other Tips",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
  ];
  const preSurgery = [
    {
      icon: exercise,
      title: "Exercises",
      bg: "#AFE0FFFF",
      color: "#333",
      content: `<div>
      <h2 className="subtitle">Recommended exercises everyday 15 days before surgery</h2>
      <div className="pointers">
        <ul>
          <li>Chair Push up <img src="image-placeholder-url" alt="Chair Push up" /></li>
          <li>Long Arc Quad <img src="image-placeholder-url" alt="Long Arc Quad" /></li>
          <li>Straight Legs Risers <img src="image-placeholder-url" alt="Long Arc Quad" /></li>
        </ul>
      </div>
    </div>`,
    },
    {
      icon: hospitalGuide,
      title: "Medication Guidelines",
      bg: "#ECF0C8FF",
      color: "#333",
      content:
        "Before you come to the hospital for your surgery you will need Provide a list of any medications you are on to your physician so they may evaluate them and take any action if necessary",
    },
    {
      icon: eatingGuide,
      title: "Eating Guidelines",
      bg: "#EDCDB4FF",
      color: "#333",
      content:
        "Eat a well-balanced diet with plenty of fiber(such as bran) NO SOLID FOOD AFTER MIDNIGHT BEFORE SURGERY, ONLY WATER",
    },
    {
      icon: homePrep,
      title: "Preparing Your Home",
      bg: "#C7E4DFFF",
      color: "#333",
      content: "",
    },
    {
      icon: preventing,
      title: "Preventing Complications",
      bg: "#D7E9DBFF",
      color: "#333",
      content: "",
    },
    {
      icon: tips,
      title: "Other Tips",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
  ];
  const dayBeforeSurgery = [
    {
      icon: bringHospital,
      title: "What to Bring to the Hospital",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: prepare,
      title: "Prepare Your Self",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    { icon: ride, title: "Ride", bg: "lightgray", color: "#333", content: "" },
    {
      icon: homePrep,
      title: "Preparing Your Home",
      bg: "#C7E4DFFF",
      color: "#333",
      content: "",
    },
    {
      icon: buy,
      title: "What to buy to be ready for surgery",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: tips,
      title: "Other Tips",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
  ];
  const postSurgery = [
    {
      icon: log,
      title: "Daily Log",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: tracker,
      title: "Tracker",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: community,
      title: "Community Access",
      bg: "#C7E4DFFF",
      color: "#333",
      content: "",
    },
    {
      icon: msg,
      title: "Message your Doctor",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: faq,
      title: "Frequently Asked Questions",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
    {
      icon: help,
      title: "App Help",
      bg: "lightgray",
      color: "#333",
      content: "",
    },
  ];

  const selectedOption =
    myProps?.condition && myProps.condition === "preSurgery"
      ? preSurgery
      : myProps?.condition && myProps.condition === "dayBeforeSurgery"
      ? dayBeforeSurgery
      : postSurgery;

  return (
    <>
    <MobileLayout/>
    <div className="guidance-container guidance-new">
      {/* <TrussLogo /> */}

      <div className="content">
        <h2>
          Here are the guidance's and the important tips which you should follow
        </h2>

        <div className="quidance-cards">
          {selectedOption.map((tip, i) => {
            const { icon, title, bg, color, content = null } = tip;
            return (
              <TipCard
                key={i + title}
                icon={icon}
                title={title}
                bgColor={bg}
                textColor={color}
                condition={myProps?.condition}
              />
            );
          })}
        </div>
      </div>
    </div>
    </>
  );
};

export default Guidance;
