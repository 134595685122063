import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Grid,
  Typography,
  Snackbar,
  FormControl,
  Select,
  TextField,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import configService from "src/services/patientService";
import { LoadingButton } from "@mui/lab";
import "./common.css";
import patientService from "src/services/patientService";
import deviceService from "src/services/deviceConfigService";
import loginService from "src/services/loginService";
import providerService from "src/services/providerService";

const DeviceConfigForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;

  const [formData, setFormData] = useState({
    deviceName: "",
    deviceKey: "",
    deviceType: "",
    patientId: "",
    providerId: "",
    issueDate: "",
    active: true,
    deviceId: "",
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [providers, setProviders] = useState([]);
  const [devices, setDevices] = useState([]);
  const userType = loginService.getUserType();

  // const handleClick = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    getPatientData();
  }, []);

  const getPatientData = async () => {
    try {
      if (userType === "admin") {
        const data = await providerService.getProviders();
        console.log("provider data", data);
        setProviders(data);
      } else {
        const deviceData = await deviceService.getDeviceConfig();
        console.log("device data", deviceData);
        setDevices(deviceData);
        const data = await patientService.getPatients();
        console.log("patient data", data);
        setPatients(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    navigate("/deviceConfig/list", { replace: true });
  };
  const handleChange = (event) => {
    console.log("name", event.target.name);
    console.log("value", event.target.value);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    setLoading(true);
    console.log("formData", formData);
    try {
      const data = await deviceService.createDeviceConfig(formData);
      console.log(data);
      navigate("/deviceConfig/list", { replace: true });
      setMsg("Configuration added successfully");
      setOpen(true);
      setLoading(false);
    } catch (error) {
      setDisable(false);
      setLoading(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  const handleDateChange = (data) => {
    console.log(data);
    setFormData({
      ...formData,
      issueDate: moment(data.$d).format("MM/DD/YYYY"),
    });
  };

  const handleDropDownChange = (event) => {
    setFormData({ ...formData, patientId: event.target.value });
  };
  const handleDropDownChangeProvider = (event) => {
    setFormData({ ...formData, providerId: event.target.value });
  };

  const handleDeviceDropdown = async (event) => {
    console.log("target", event.target);
    let value = event.target.value;
    setFormData({ ...formData, deviceId: value });
    const deviceData = await deviceService.getDeviceConfigById(
      event.target.value
    );
    console.log("deviceData", deviceData);
    if (deviceData) {
      const data = {
        deviceName: deviceData.deviceName,
        deviceKey: deviceData.deviceKey,
        deviceType: deviceData.deviceType,
        patientId: deviceData.patientId,
        providerId: deviceData.providerId,
        issueDate: deviceData.issueDate,
        deviceId: deviceData.id,
      };

      setFormData(data);
    }
  };
  return (
    <>
      <Helmet>
        <title> Add Configurations | Truss Health </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Add Device Configuration
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 6, minWidth: 200 }}>
          <Grid container spacing={2}>
            {userType === "admin" ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Device Name"
                  name="deviceName"
                  value={formData.deviceName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth style={{ marginTop: "3%" }}>
                  <InputLabel id="simple-select-label">Device Name</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={formData.deviceId}
                    label="Device Name"
                    onChange={handleDeviceDropdown}
                  >
                    {devices?.map((device, id) => (
                      <MenuItem key={id} name={device.id} value={device.id}>
                        {device.deviceName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Device Key"
                name="deviceKey"
                value={formData.deviceKey}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={userType === "admin" ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Device Type"
                name="deviceType"
                value={formData.deviceType}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={userType === "admin" ? false : true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className="dateFormat">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Issue Date"
                    onChange={(newValue) => handleDateChange(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            {userType === "admin" ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">Provider</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={formData.providerId}
                    label="Provider"
                    onChange={handleDropDownChangeProvider}
                  >
                    {providers?.map((provider) => (
                      <MenuItem value={provider.id}>{provider.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">Patient</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={formData.patientId}
                    label="Patient"
                    onChange={handleDropDownChange}
                  >
                    {patients?.map((patient) => (
                      <MenuItem value={patient.id}>{patient.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}></Grid>
            {myProps?.id ? (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Update
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default DeviceConfigForm;
