import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import TrussLogo from "src/components/truss-logo/TrussLogo";
import plusIcon from "./icons/plus-circle.svg";
import patientService from "src/services/patientService";

const WelcomeBack = () => { 
  const navigate = useNavigate();
  const location = useLocation();
  // const myProps = location.state;
  const [data, setData] = useState({});
  const [assistance, setAssistance] = useState("");
  const [dates, setDates] = useState([]);
  const [skipped, setSkipped] = useState(true);
  // const data = {
  //   name: "Nishika",
  //   daysLeft: "19th July, 2023",
  // targetDate: "2023-09-10",
  // };

  function addDailyLogs() {
    //
  }
  function generateDateArray(surgeryDate) {
    console.log("surgeryDate", surgeryDate);
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

    const today = new Date();
    const eventDate = new Date(surgeryDate);

    const dateArray = [];

    let currentDate = new Date(twoDaysAgo);
    while (currentDate < eventDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (!dateArray.some((date) => date.getTime() === eventDate.getTime())) {
      dateArray.push(eventDate);
    }

    return dateArray;
  }

  useEffect(() => {
    getPatientData();
  }, []);

  const getPatientData = async () => {
    try {
      const data = await patientService.getPatientDataForFlow();
      console.log(data);
      setDates(
        generateDateArray(moment(data?.surgeryDate).format("YYYY-MM-DD"))
      );
      const dateNow = moment();
      const dateSurgery = moment(data.surgeryDate);
      data.daysToSurgery = dateSurgery.diff(dateNow, "days");

      console.log("data", data);
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log("assistance", assistance);
      if (assistance) {
        const data1 = await patientService.createPatientDailyLog(
          assistance,
          data?.id,
          data?.daysToSurgery
        );
        console.log(data1);
      } else {
        console.log("no text typed");
      }
      navigate("/knee-qs");
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setSkipped(false);
    const assistance = e.target.value;

    setAssistance(assistance);
    if (assistance === "") {
      setSkipped(true);
    }
  };
  return (
    <div className="welcome-back-container">
      <TrussLogo />

      <div className="content">
        <h1>Welcome Back, {data.name}!</h1>
        <p>
          Day before Surgery = <span>{data.daysToSurgery}</span>
        </p>

        <div className="calendar">
          {dates.map((date, i) => {
            return (
              <div
                key={date.toLocaleString()}
                className={`dateCard ${
                  i == 2 || i == dates.length - 1 ? "active" : ""
                }`}
              >
                <span className="date">{date.getDate()}</span>
                <span className="day">
                  {date.toLocaleDateString("en-US", { weekday: "short" })}
                </span>
                {i === dates.length - 1 && (
                  <span className="label">Surgery</span>
                )}
              </div>
            );
          })}
        </div>

        {/* <div className="assistance">
          <h4>Health Assistance</h4>

          <textarea
            rows="10"
            placeholder="Type something..."
            value={assistance}
            onChange={handleChange}
          ></textarea>
        </div> */}

        <div className="log">
          {/* <h4>Daily Log</h4>
          <button onClick={addDailyLogs}>
            <img src={plusIcon} alt="" />
          </button> */}
        </div>

        <button type="button" onClick={handleSubmit}>
          {skipped ? "Next" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default WelcomeBack;
