import Cookies from "universal-cookie";
import * as jose from "jose";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const cookies = new Cookies();
const axios = new Axios();
const baseUrl = BASEURL;

class LoginService {
  loginUser(username, password, userType) {
    const option = {
      url: `${baseUrl}/login/${userType}`,
      data: { email: username, password },
    };

    return axios.post(option);
  }

  doLogin(token) {
    this.token = token;
    cookies.set("truss_session", token, { path: "/" });
    const decodedToken = jose.decodeJwt(token);
    cookies.set("userFlag", decodedToken.userType, { path: "/" });
  }

  dologout() {
    cookies.remove("truss_session", {
      path: "/",
      domain: "app.trusshealth.ai",
    });
    cookies.remove("userFlag", { path: "/", domain: "app.trusshealth.ai" });
    cookies.remove("avatar", { path: "/", domain: "app.trusshealth.ai" });
    cookies.remove("truss_session", { path: "/", domain: "localhost" });
    cookies.remove("userFlag", { path: "/", domain: "localhost" });
    cookies.remove("avatar", { path: "/", domain: "localhost" });
  }

  setUserType(data) {
    cookies.set("userFlag", data, { path: "/" });
  }

  getUserType() {
    const userType = cookies.get("userFlag");
    console.log("userFlag", userType);
    return userType;
  }

  getparamsFromCookie(value) {
    this.value = value;
    const token = cookies.get("truss_session");
    if (!token) {
      return "";
    }
    const decodedToken = jose.decodeJwt(token);
    // decode(token, { complete: true });
    let data;
    if (decodedToken) {
      switch (value) {
        case "username":
          data = decodedToken.name;
          break;
        case "email":
          data = decodedToken.email;
          break;
        case "userId":
          data = decodedToken.userId;
          break;
        case "accountId":
          data = decodedToken.accountId;
          break;
        case "avatar":
          data = decodedToken.avatar;
          break;
        case "jwt_session":
          data = token;
          break;
        case "userType":
          data = decodedToken.userType;
          break;
        case "id":
          data = decodedToken.id;
          break;
        default:
          data = "";
      }
    }

    return data;
  }

  setAvatar(link) {
    cookies.set("avatar", link, { path: "/" });
  }

  getAvatar() {
    const avatar = cookies.get("avatar");

    return avatar;
  }

  getServerUptime() {
    const option = {
      url: `${baseUrl}/api/uptime`,
    };

    return axios.get(option);
  }
  getUserId() {
    const id = cookies.get("id");

    return id;
  }

  checkRoute() {
    const userType = cookies.get("userFlag");
    if(userType === "admin") {
      return "/admin";
    } else {
      return "/";
    }
  }
}
const loginService = new LoginService();
export default loginService;
