import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Grid,
  Typography,
  Snackbar,
  FormControl,
  Select,
  TextField,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "./common.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import configService from "src/services/patientService";
import providerService from "src/services/providerService";
import displayService from "src/services/displayService";
import {
  PREPROCEDUREOPTIONS,
  PREPROCEDUREOLDOPTIONS,
  SAMEDAYOPTIONS,
  POSTPROCEDUREOPTIONS,
} from "src/constants/constants";

const ConfigForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;

  const [formData, setFormData] = useState({
    flowType: "",
    flowName: "",
    sequence: "",
    providerId: "",
    active: true,
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  // const handleClick = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    getProviderData();
  }, []);

  const getProviderData = async () => {
    try {
      const data = await providerService.getProviders();
      console.log("provider data", data);
      setProviders(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("name", event.target.name);
    console.log("value", event.target.value);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleDropDownChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleBack = () => {
    navigate("/flowConfig/list", { replace: true });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    console.log(formData);
    try {
      const data = await displayService.createDisplayConfig(formData);
      console.log(data);
      navigate("/flowConfig/list", { replace: true });
      setMsg("Configuration added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      console.log(error);
      setMsg(error?.response?.data?.error);
      setOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title> Flow Configurations | Truss Health </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Patient Flow Configuration
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 6, minWidth: 200 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth style={{ marginTop: "4%" }}>
                <InputLabel id="simple-select-label">Flow Type</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={formData.flowType}
                  label="Flow Type"
                  name="flowType"
                  onChange={handleDropDownChange}
                >
                  <MenuItem value="Pre-Procedure(First Time Login)">
                    Pre-Procedure(First Time Login)
                  </MenuItem>
                  <MenuItem value="Pre-Procedure">Pre-Procedure</MenuItem>
                  <MenuItem value="Same Day Procedure">
                    Same Day Procedure
                  </MenuItem>
                  <MenuItem value="Post-Procedure">Post-Procedure</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth style={{ marginTop: "4%" }}>
                <InputLabel id="simple-select-label">Flow Name</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={formData.flowName}
                  label="Flow Name"
                  name="flowName"
                  onChange={handleDropDownChange}
                >
                  {formData.flowType === "Pre-Procedure(First Time Login)"
                    ? PREPROCEDUREOPTIONS.map((option) => (
                        <MenuItem value={option.name}>{option.name}</MenuItem>
                      ))
                    : formData.flowType === "Pre-Procedure"
                    ? PREPROCEDUREOLDOPTIONS.map((option) => (
                        <MenuItem value={option.name}>{option.name}</MenuItem>
                      ))
                    : formData.flowType === "Same Day Procedure"
                    ? SAMEDAYOPTIONS.map((option) => (
                        <MenuItem value={option.name}>{option.name}</MenuItem>
                      ))
                    : formData.flowType === "Post-Procedure"
                    ? POSTPROCEDUREOPTIONS.map((option) => (
                        <MenuItem value={option.name}>{option.name}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
              {/* <TextField
                required
                label="Flow Name"
                name="flowName"
                value={formData.flowName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginTop: "0%" }}
                required
                label="Sequence"
                name="sequence"
                value={formData.sequence}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="simple-select-label">Provider</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={formData.providerId}
                  label="Provider"
                  name="providerId"
                  onChange={handleDropDownChange}
                >
                  {providers.map((provider) => (
                    <MenuItem value={provider.id}>{provider.name}</MenuItem>
                  ))}
                  {/* <MenuItem value="Post-Procedure">Post-Procedure</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            {myProps?.id ? (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Update
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default ConfigForm;
