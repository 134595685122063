import React, { useEffect, useState } from "react";

const BloodTest = ({ handleChange, page }) => {
  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    const note = e.target.value;
    setNote(note);
  };

  useEffect(() => {
    handleChange(page, note);
  }, [note]);

  return (
    <div className="bloodtest">
      <h3>Scan the machine or put CRP Levels Manually</h3>

      <textarea
        rows="10"
        placeholder="Log a symptom or type a note..."
        value={note}
        onChange={handleNoteChange}
      ></textarea>

      <div className="img">
        <img src={require("./machine.png")} alt="" />
      </div>
    </div>
  );
};

export default BloodTest;
