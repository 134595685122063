import React, { useEffect, useState } from "react";

const Notes = ({ handleChange, page }) => {
  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    const note = e.target.value;
    setNote(note);
  };

  useEffect(() => {
    handleChange(page, note);
  }, [note]);

  return (
    <div className="notes">
      <h3>Notes</h3>

      <textarea
        rows="10"
        placeholder="Log a symptom or type a note..."
        value={note}
        onChange={handleNoteChange}
      ></textarea>
    </div>
  );
};

export default Notes;
