import React, { useEffect, useState } from "react";

const MentalHealth = ({ handleChange, page }) => {
  const [qna, setQna] = useState([
    {
      ques: "Little interest or pleasure in doing things",
      ans: 0,
    },
    {
      ques: "Feeling down, depressed or hopeless",
      ans: 0,
    },
    {
      ques: "Trouble falling asleep, staying asleep, or sleeping too much",
      ans: 0,
    },
    {
      ques: "Feeling tired or having little energy",
      ans: 0,
    },
    {
      ques: "Poor appetite or overeating",
      ans: 0,
    },
    {
      ques: "Feeling bad about yourself- or that you're a failure or have let yourself or your family down",
      ans: 0,
    },
    {
      ques: "Trouble concentrating on things, such as reading the newspaper or watching television",
      ans: 0,
    },
    {
      ques: "Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
      ans: 0,
    },
    {
      ques: "Thoughts that you would be better off dead or of hurting yourself in some way",
      ans: 0,
    },
  ]);

  // qi : question-index
  function updateAnswer(qi, value) {
    let temp = qna.slice();
    temp[qi].ans = value;

    setQna(temp);
  }

  useEffect(() => {
    handleChange(page, qna);
  }, [qna]);

  return (
    <div className="mental">
      <h2>
        Over the <span>last 2 weeks</span> how often you have been bothered by
        the following problems?
      </h2>

      {qna.map((question, i) => {
        return (
          <div key={i} className="question">
            <p className="ques">{question.ques}</p>
            <input
              type="range"
              step={1}
              min={1}
              max={3}
              defaultValue={Math.min(Math.max(1, question.ans), 3)}
              onChange={(e) => updateAnswer(i, e.target.value)}
            />
            <p>
              <span>Not at all</span>
              <span>Several days</span>
              <span>Everyday</span>
            </p>
          </div>
        );
      })}
    </div>
  );
};
export default MentalHealth;
