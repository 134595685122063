// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconCustom {
  width: 25% !important;
  height: 25% !important;
}

.scroll {
  overflow-y:scroll
}`, "",{"version":3,"sources":["webpack://./src/components/tip-card/tipcard.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE;AACF","sourcesContent":[".iconCustom {\n  width: 25% !important;\n  height: 25% !important;\n}\n\n.scroll {\n  overflow-y:scroll\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
