import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
// import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import PatientLoginPage from "./pages/patientLoginPage";
import Page404 from "./pages/Page404";
import DashboardPage from "./pages/Dashboard";
import DashboardDetailsPage from "./pages/DashboardDetails";
import ConfigForm from "./pages/AddEditConfig";
//import SurgeryEntry from "./pages/logsEntry/surgeryEntry";
// import DashboardPage from "./pages/DashboardPage";
// import ReportPage from "./pages/ReportPage";
//import UploadPage from "./pages/uploadPage";
import UserForm from "./pages/AddEditUsers";
import PatientForm from "./pages/AddEditPatient";
import PatientPage from "./pages/patientPage";
import CareLoginPage from "./pages/careLoginPage";
import CareProvider from "./pages/care-provider/CareProvider";
import TrussPatientForm from "./components/patient-form/TrussPatientForm";
import TrussDoctorForm from "./components/doctor-form/TrussDoctorForm";
import Guidance from "./pages/Guidance";
import Symptoms from "./pages/Symptoms";
import UploadPhoto from "./pages/UploadPhoto";
import WelcomeBack from "./pages/WelcomeBack";
import KneeQs from "./pages/KneeQs";
import SurgeryDay from "./pages/surgeryDay";
import AdminLoginPage from "./pages/AdminLogin";
import ProviderPage from "./pages/providerPage";
import ProviderForm from "./pages/AddEditProvider";
import CareProviderPage from "./pages/careProviderPage";
import CareProviderForm from "./pages/AddEditCareProvider";
import PatientList from "./pages/patientListCare";
import DisplayConfigPage from "./pages/displayConfigPage";
import DeviceConfigPage from "./pages/deviceConfigPage";
import DeviceConfigForm from "./pages/AddEditDeviceConfig";
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/careLogin",
      element: <CareLoginPage />,
    },
    {
      path: "/patientList",
      element: <PatientList />,
    },
    {
      path: "/",
      element: <LoginPage />,
    },
    // ------------------------------
    {
      path: "/care-provider",
      element: <CareProvider />,
      children: [],
    },
    {
      path: "/patient-form",
      element: <TrussPatientForm />,
    },
    {
      path: "/doctor-form",
      element: <TrussDoctorForm />,
    },
    {
      path: "/guidance",
      element: <Guidance />,
    },
    {
      path: "/upload-photo",
      element: <UploadPhoto />,
    },
    {
      path: "/symptoms",
      element: <Symptoms />,
    },
    {
      path: "/admin",
      element: <AdminLoginPage />,
    },
    {
      path: "/patientlogin",
      element: <PatientLoginPage />,
    },
    {
      path: "/welcome-back",
      element: <WelcomeBack />,
    },
    {
      path: "/knee-qs",
      element: <KneeQs />,
    },
    {
      path: "/surgeryDay",
      element: <SurgeryDay />,
    },

    // -------------------------------
    {
      path: "/404",
      element: <Page404 />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/list" />, index: true },
        { path: "list", element: <DashboardPage /> },
        { path: "patient", element: <DashboardDetailsPage /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/patients",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/patients/list" />, index: true },
        { path: "list", element: <PatientPage /> },
        { path: "add", element: <PatientForm /> },
        { path: "edit", element: <PatientForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/providers",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/providers/list" />, index: true },
        { path: "list", element: <ProviderPage /> },
        { path: "add", element: <ProviderForm /> },
        { path: "edit", element: <ProviderForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/care-providers",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/care-providers/list" />, index: true },
        { path: "list", element: <CareProviderPage /> },
        { path: "add", element: <CareProviderForm /> },
        { path: "edit", element: <CareProviderForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/users",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/users/list" />, index: true },
        { path: "list", element: <UserPage /> },
        { path: "add", element: <UserForm /> },
        { path: "edit", element: <UserForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/flowConfig",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/flowConfig/list" />, index: true },
        { path: "list", element: <DisplayConfigPage /> },
        { path: "add", element: <ConfigForm /> },
        { path: "edit", element: <ConfigForm /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/deviceConfig",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/deviceConfig/list" />, index: true },
        { path: "list", element: <DeviceConfigPage /> },
        { path: "add", element: <DeviceConfigForm /> },
        { path: "edit", element: <DeviceConfigForm /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    // {
    //   path: "/prompt_config",
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/prompt_config/list" />, index: true },
    //     { path: "list", element: <PromptConfigPage /> },
    //     { path: "add", element: <ConfigPromptForm /> },
    //     { path: "404", element: <Page404 /> },
    //     { path: "*", element: <Navigate to="/404" /> },
    //   ],
    // },
    // {
    //   path: "/upload",
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/upload/file" />, index: true },
    //     { path: "file", element: <UploadPage /> },
    //     { path: "404", element: <Page404 /> },
    //     { path: "*", element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
