import React, { useEffect, useState } from "react";
import TrussLogo from "src/components/truss-logo/TrussLogo";

const Symptoms = ({ handleChange, page }) => {
  const [symptoms, setSymptoms] = useState([
    { symptom: "All is good", value: false },
    { symptom: "Swelling", value: false },
    { symptom: "Vomiting", value: false },
    { symptom: "Soreness", value: false },
    { symptom: "Constipation", value: false },
    { symptom: "Gas", value: false },
    { symptom: "Wheezing", value: false },
    { symptom: "Tightness\nin chest", value: false },
    { symptom: "Indigestion", value: false },
    { symptom: "Diarrhea", value: false },
    { symptom: "Fatigue", value: false },
    { symptom: "Bruising", value: false },
    { symptom: "Thirst", value: false },
    { symptom: "Itching", value: false },
    { symptom: "Fever", value: false },
    { symptom: "Insomnia", value: false },
    { symptom: "Nausea", value: false },
    { symptom: "Bloating", value: false },
    { symptom: "Restlessness", value: false },
    { symptom: "Cough", value: false },
    { symptom: "Hot Flashes", value: false },
  ]);

  function selectSymptom(symp) {
    const index = symptoms.findIndex((obj) => obj.symptom === symp);

    const updatedSymptoms = symptoms;
    updatedSymptoms[index].value = !updatedSymptoms[index].value;

    setSymptoms([...updatedSymptoms]);
  }

  useEffect(() => {
    handleChange(page, symptoms);
  }, [symptoms]);

  return (
    <div className="symptoms-container">
      <div className="content">
        <h2>Please select all symtoms that you are facing today.</h2>

        <div className="all-symptoms">
          {symptoms.map((obj, i) => {
            const { symptom, value } = obj;

            return (
              <div
                key={i + symptom.replace(" ", "_")}
                className={`symptom ${value ? "active" : ""}`}
                onClick={() => selectSymptom(symptom)}
              >
                <span>{symptom}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Symptoms;
