import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import loginService from "../../../services/loginService";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function AdminLoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [msg, setMsg] = useState("");
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      setDisable(true);
      console.log("here");
      console.log(state.email);
      console.log(state.password);
      if (state.email && state.password) {
        const loginRes = await loginService.loginUser(
          state.email,
          state.password,
          "admin"
        );
        loginService.doLogin(loginRes?.data.token);
        loginService.setUserType("admin");
        if (loginRes?.data.token) {
          setMsg("You have successfully logged in!");
          setOpen(true);
          navigate("/providers", { replace: true });
        } else {
          setDisable(false);
          setLoading(false);
          console.log("Unsuccessful login");
        }
      } else {
        setDisable(false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setDisable(false);
      console.log(`error in login`);
      setMsg("Login Failed! Please check your credentials and try again!");
      setOpen(true);
      console.log(err);
    }
  };

  const textInputChange = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <form onSubmit={handleClick} method="post">
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            onChange={textInputChange}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            onChange={textInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <LoadingButton
            fullWidth
            disable={disable}
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={handleClick}
          >
            Login
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
