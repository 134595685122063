export default function generateRandomPassword() {
  // Character sets
  const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numericChars = "0123456789";

  // Initialize the password with one character from each category
  let password = getRandomChar(specialChars) +
                 getRandomChar(uppercaseChars) +
                 getRandomChar(lowercaseChars);

  // Generate the remaining characters randomly
  const allChars = specialChars + uppercaseChars + lowercaseChars + numericChars;
  const minLength = 8; // Minimum password length
  const maxLength = 16; // Maximum password length

  while (password.length < maxLength) {
    const randomChar = getRandomChar(allChars);
    password += randomChar;
  }

  // Shuffle the password to randomize character order
  password = shuffleString(password);

  return password;
}

function getRandomChar(charSet) {
  const randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet[randomIndex];
}

function shuffleString(str) {
  const array = str.split("");
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array.join("");
}

