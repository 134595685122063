// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-buton {
  width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10%;
    margin: 5%;
}

.truss-logo {
  margin: 5%;
}

.main-div-phone {
  width: 100%;
  position: fixed;
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/common.css"],"names":[],"mappings":"AAAA;EACE,WAAW;IACT,aAAa;IACb,2BAA2B;IAC3B,iBAAiB;IACjB,UAAU;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".logout-buton {\n  width: 100%;\n    display: flex;\n    flex-direction: row-reverse;\n    margin-right: 10%;\n    margin: 5%;\n}\n\n.truss-logo {\n  margin: 5%;\n}\n\n.main-div-phone {\n  width: 100%;\n  position: fixed;\n  background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
