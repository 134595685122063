import React, { useEffect, useRef, useState } from "react";

const YesNo = ({ handleChange }) => {
  const [value, setValue] = useState(true);

  useEffect(() => {
    handleChange(value ? "Yes" : "No");
  }, [value]);

  return (
    <div className="yesno-inputs toggle">
      <div onClick={() => setValue(!value)}>
        <button className={`btn ${value ? "active" : ""}`}>Yes</button>
        <button className={`btn ${value ? "" : "active"}`}>No</button>
      </div>
    </div>
  );
};

export default YesNo;
