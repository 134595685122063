import React, { useEffect, useState } from "react";

const Pain = ({ handleChange, page }) => {
  const [qna, setQna] = useState([
    {
      ques: "On a scale from 1 to the most pain you have ever felt, how much pain are you in today?",
      ans: 0,
    },
    {
      ques: "On a scale from 1 to the worst it has been since the completion of your surgery, how much swelling are you visibly seeing today?",
      ans: 0,
    },
    {
      ques: "On a scale from 1 to the most pain you have ever felt, how much pain are you in today?",
      ans: 0,
    },
    {
      ques: "On a scale from 1 to the most pain you have ever felt, how much pain are you in today?",
      ans: 0,
    },
  ]);

  // qi : question-index
  function updateAnswer(qi, value) {
    let temp = qna.slice();
    temp[qi].ans = value;
    
    setQna(temp);
  }

  useEffect(() => {
    handleChange(page, qna);
  }, [qna]);

  return qna.map((question, i) => {
    return (
      <div key={i} className="question">
        <p className="ques">{question.ques}</p>
        <input
          type="range"
          step={1}
          min={1}
          max={10}
          defaultValue={Math.min(Math.max(1, question.ans), 10)}
          onChange={(e) => updateAnswer(i, e.target.value)}
        />
        <p>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
          <span>6</span>
          <span>7</span>
          <span>8</span>
          <span>9</span>
          <span>10</span>
        </p>
      </div>
    );
  });
};
export default Pain;
