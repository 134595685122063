import React, { useEffect, useRef, useState } from "react";

const MultiSelect = ({ options, handleChange, msq = true }) => {
  const [values, setValues] = useState(new Set([]));
  const [value, setValue] = useState('');

  function updateOptions(value) {
    if (msq) {
      setValues((prev) => {
        if (prev.has(value)) {
          prev.delete(value);
          return new Set([...prev]);
        } else return new Set([...prev, value]);
      });
      return;
    }

    setValue(value);
  }

  useEffect(() => {
    handleChange([...values]);
  }, [values]);

  useEffect(() => {
    handleChange(value);
  }, [value]);

  return (
    <div className="multi-select">
      {options?.map((val, i) => {
        return (
          <div
            key={i}
            onClick={() => updateOptions(val)}
            className={(values.has(val) || value===val) ? "active" : ""}
          >
            <p>{val}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MultiSelect;
