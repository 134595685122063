import React, { Component } from "react";
import loginService from "src/services/loginService";

class ServerUptime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uptime: null,
    };
  }

  componentDidMount() {
    this.fetchUptime();
  }

  fetchUptime = async () => {
    try {
      const data = await loginService.getServerUptime();
      console.log("data", data);
      this.setState({ uptime: data.uptime });
    } catch (err) {
      console.error("Error fetching uptime:", err);
      this.setState({ uptime: 0 });
    }
  };

  formatUptime = (uptime) => {
    console.log("uptime", uptime);
    const minutes = Math.floor((uptime / 60) % 60);
    const hours = Math.floor((minutes / 60));
    const days = Math.floor(hours / 24);
    console.log("days", days);

    return `${days}d ${hours}h ${minutes}m`;
  };

  render() {
    const { uptime } = this.state;
    return (
      <div style={{color: "black"}}>
        {uptime !== null ? (
          <p>Uptime: {this.formatUptime(uptime)}</p>
        ) : (
          <p>Loading uptime...</p>
        )}
      </div>
    );
  }
}

export default ServerUptime;
