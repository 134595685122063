import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useEffect, useRef, useState } from "react";

const CalendarInput = ({ handleChange }) => {
  const [value, setValue] = useState(dayjs(new Date()));

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!firstUpdate.current) handleChange(value._d || value.$d);
    else firstUpdate.current = false;
  }, [value]);

  return (
    <div className="cal-input">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar onChange={(newValue) => setValue(newValue)} />
      </LocalizationProvider>
    </div>
  );
};

export default CalendarInput;
