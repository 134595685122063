import React, { useEffect, useState } from "react";
import TrussLogo from "../truss-logo/TrussLogo";
import Question from "./components/Question";
import UserInput from "./components/UserInput";

import ArrowRightIcon from "./arrow-right.svg";
import { useNavigate, useLocation } from "react-router-dom";
import patientService from "src/services/patientService";
import displayService from "src/services/displayService";

const TrussPatientForm = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);

  const questions = [
    {
      ques: "Date of your Surgery?",
      type: "calendar",
      id: 1,
      name: "Patient surgery Date",
    },
    {
      ques: "What is your current weight?",
      type: "text",
      id: 1,
      name: "Weight Question",
    },
    {
      ques: "Select your Gender:",
      type: "radio-group",
      options: ["Male", "Female", "Transgender", "Non-Binary", "Others"],
      id: 1,
      name: "Gender",
    },
    {
      ques: "Existing Conditions (Yes/No)",
      type: "yes-no",
      options: {
        Diabetes: false,
        "High blood pressure": false,
        Cancer: false,
        "Congestive Heart Failure": false,
        Allergies: false,
      },
      id: 1,
      name: "Existing Conditions",
    },
  ];

  function nextQuestion() {
    if (questionNumber === questions.length - 1) {
      submitForm();
      return;
    }

    setQuestionNumber(Math.min(questionNumber + 1, 3));
  }

  function prevQuestion() {
    setQuestionNumber(Math.max(questionNumber - 1, 0));
  }

  async function submitForm() {
    try {
      console.log("SUBMITTED", userAnswers);
      const data = await patientService.postFirstPatientFlow(
        userAnswers,
        myProps?.id
      );
      console.log(data);
      navigate("/guidance", {
        state: {
          condition: "preSurgery",
        },
        replace: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getQuestionsSeq();
  }, []);

  const getQuestionsSeq = async () => {
    try {
      const data = await displayService.getQuestionSort(
        "Pre-Procedure(First Time Login)"
      );
      console.log(data);
      if (data?.length) {
        const questionArr = [];
        for (const quest of data) {
          const question = questions.find((q) => q.name === quest?.flowName);
          if (question) {
            questionArr.push(question);
          }
        }
        console.log(questionArr);
        setQuestionData(questionArr);
      } else {
        setQuestionData(questions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="truss-form-container">
      <div className="logo-wrapper">
        <TrussLogo />
      </div>
      <div className="question-div">
        <div
          className="question-wrapper"
          style={{
            transform: `
            translateX(calc(${questionNumber * -80}px + ${
              -100 * questionNumber
            }%))`,
          }}
        >
          {questions.map((question, i) => {
            const { ques, type } = question;
            return (
              <div key={ques}>
                <Question number={i + 1} question={ques} />
                <UserInput
                  type={type}
                  number={i}
                  setUserAnswers={setUserAnswers}
                  questionData={question}
                  options={question.options}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="nav-btns">
        <button
          className={questionNumber ? "" : "disable"}
          type="button"
          onClick={prevQuestion}
        >
          <img src={ArrowRightIcon} alt="Icon" />
        </button>

        <button type="button" onClick={nextQuestion}>
          {questionNumber == questions.length - 1 ? (
            "Submit"
          ) : (
            <img src={ArrowRightIcon} alt="Icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default TrussPatientForm;
