import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class DisplayConfigService {
  getDisplayConfig() {
    const option = {
      url: `${baseUrl}/displayConfig`,
    };

    return axios.get(option);
  }

  createDisplayConfig(data) {
    const option = {
      url: `${baseUrl}/displayConfig`,
      data,
    };

    return axios.post(option);
  }

  updateDisplayConfig(data) {
    const option = {
      url: `${baseUrl}/displayConfig/${data?.id}`,
      data,
    };

    return axios.put(option);
  }

  getDisplayConfigByDocId(id) {
    const option = {
      url: `${baseUrl}/displayConfig/${id}`,
    };

    return axios.get(option);
  }

  getQuestionSort(flowType) {
    const option = {
      url: `${baseUrl}/displayConfig/questionSort/${flowType}`,
    };

    return axios.get(option);
  }
  deleteDisplayConfig(id) {
    const option = {
      url: `${baseUrl}/displayConfig/${id}`,
    };

    return axios.delete(option);
  }
}

const displayService = new DisplayConfigService();
export default displayService;
