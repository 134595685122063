import React, { useState } from "react";
import "./tipcard.css";

// Static Components
import {
  DailyLog,
  EatingGuidelines,
  ExercisesContent,
  MedicationGuidelines,
  OtherTips,
  PrepareYourSelf,
  PreparingYourHomeBefore,
  PreparingYourHomePre,
  PreventingComplications,
  WhatToBringToTheHospital,
  WhatToBuyForSurgery,
  FAQ
} from "./CardContentComponents";

const TipCard = ({ icon, title, bgColor, textColor, condition }) => {
  const [infoActive, setInfoActive] = useState(false);

  function closeInfo() {
    setInfoActive(false);
  }

  return (
    <>
      <div
        className="tip-card"
        style={{ backgroundColor: bgColor, color: textColor }}
        onClick={() => setInfoActive(!infoActive)}
      >
        <div className="icon iconCustom">
          <img src={icon} alt={title} />
        </div>
        <p className="tip-text">{title}</p>
      </div>

      <div
        className={`tip_card_overlay ${infoActive ? "active" : ""}`}
        onClick={closeInfo}
      >
        <div className="tip_card_content" onClick={(e) => e.stopPropagation()}>
          <p className="close-icon" onClick={closeInfo}>
            <span>Close</span>
          </p>

          {"preSurgery dayBeforeSurgery".includes(condition) && (
            <h2>{title}</h2>
          )}

          <div className="content-container">
            {condition == "preSurgery" ? (
              title == "Exercises" ? (
                <ExercisesContent />
              ) : title === "Medication Guidelines" ? (
                <MedicationGuidelines />
              ) : title === "Eating Guidelines" ? (
                <EatingGuidelines />
              ) : title === "Preparing Your Home" ? (
                <PreparingYourHomePre />
              ) : title === "Preventing Complications" ? (
                <PreventingComplications />
              ) : title === "Other Tips" ? (
                <OtherTips />
              ) : (
                <></>
              )
            ) : condition == "dayBeforeSurgery" ? (
              title === "What to Bring to the Hospital" ? (
                <WhatToBringToTheHospital />
              ) : title === "Prepare Your Self" ? (
                <PrepareYourSelf />
              ) : title === "Preparing Your Home" ? (
                <PreparingYourHomeBefore />
              ) : title === "What to buy to be ready for surgery" ? (
                <WhatToBuyForSurgery />
              ) : (
                <></>
              )
            ) : title === "Daily Log" ? (
              <DailyLog closeSelf={closeInfo} />
            ) : title === "Tracker" ? (
              <p>Tracker</p>
            ) : title === "Frequently Asked Questions" ? (
              <FAQ/>
            ): (<></>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TipCard;
