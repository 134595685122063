import Axios from '../helper/axiosHelper';
import { BASEURL } from 'src/constants/constants';

const axios = new Axios();
const baseUrl = BASEURL;


class DashboardService {
  getDashboard() {
    const option = {
      url: `${baseUrl}/dashbaord`,
    };

    return axios.post(option);
  }

  getDashboardDetails(id) {
    const option = {
      url: `${baseUrl}/dashbaord/${id}`,
    };

    return axios.get(option);
  }


  getRegisterReadings(data) {
    const option = {
      url: `${baseUrl}/register-readings`,
      data: data
    };
    return axios.post(option);
  }

  getConfiguration() {
    const option = {
      url: `${baseUrl}/configuration`
    };

    return axios.get(option);
  }

  getGraphData(registerId) {
    const option = {
      url: `${baseUrl}/register-graph`,
      data: {registerId}
    };

    return axios.post(option);
  }



}

const dashboardService = new DashboardService();
export default dashboardService;
