import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  // Avatar,
  Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
//import EditIcon from "@mui/icons-material/Edit";
// sections
import { UserListHead } from "../sections/@dashboard/user";
//import userService from "src/services/userService";
import dashboardService from "src/services/dashboard";
import moment from "moment";
// mock
//import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "details", label: "Details", alignRight: false },
  { id: "day3", label: "Day 3", alignRight: false },
  { id: "day5", label: "Day 5", alignRight: false },
  { id: "day7", label: "Day 7", alignRight: false },
  { id: "day10", label: "Day 10", alignRight: false },
  { id: "graph", label: "Graph", alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: "isContext", label: "Context Display", alignRight: false },
  // { id: "frequency", label: "Trigger Frequency", alignRight: false },
  //{ id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DashboardDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("id");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addButton, setAddButton] = useState(false);

  const [configData, setConfig] = useState([]);
  useEffect(() => {
    getDashboardDetails();
  }, []);

  const getDashboardDetails = async () => {
    try {
      let configData = await dashboardService.getDashboardDetails(myProps.id);
      console.log("configData", configData);
      setConfig(configData);
    } catch (err) {
      console.log(err);
      // navigate("/404", { replace: true });
    }
  };

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = configData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - configData.length) : 0;

  const filteredUsers = applySortFilter(
    configData,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length;

  const handleback = () => {
    navigate("/dashboard/list", { replace: true });
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Truss Health </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {myProps?.name}
          </Typography>
          <IconButton
            aria-label="back"
            color="primary"
            onClick={handleback}
          >
            <ArrowBackIcon />
          </IconButton>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={configData.length}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { details, day3, day5, day7, day10, graph } = row;
                      const selectedUser = selected.indexOf(details) !== -1;

                      return (
                        <TableRow
                          hover
                          key={details}
                          tabIndex={-1}
                          //role="checkbox"
                          //selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                          </TableCell> 

                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatar} /> 
                              <TableCell align="left">{id}</TableCell>
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left">
                            {details?.toString()}
                          </TableCell>
                          <TableCell align="left">
                            {day3?.toString() ? day3?.toString() : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {day5?.toString() ? day5?.toString() : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {day7?.toString() ? day7?.toString() : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {day10?.toString() ? day10?.toString() : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {graph?.toString() ? graph?.toString() : "N/A"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          {/* <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography> */}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={configData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
