import Cookies from "universal-cookie";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const cookies = new Cookies();
const baseUrl = BASEURL;

class PatientService {
  getPatients() {
    const option = {
      url: `${baseUrl}/patient`,
    };

    return axios.get(option);
  }
  createPatient(data) {
    const option = {
      url: `${baseUrl}/patient`,
      data,
    };

    return axios.post(option);
  }

  deletePatient(id) {
    const option = {
      url: `${baseUrl}/patient/${id}`,
    };

    return axios.delete(option);
  }

  getPatientById(id) {
    const option = {
      url: `${baseUrl}/patient/${id}`,
    };

    return axios.get(option);
  }

  updatePatient(data) {
    const option = {
      url: `${baseUrl}/patient`,
      data,
    };

    return axios.put(option);
  }

  getPatientDataForFlow() {
    const option = {
      url: `${baseUrl}/patientdata`,
    };

    return axios.get(option);
  }

  postFirstPatientFlow(data, id) {
    const option = {
      url: `${baseUrl}/patientdata/${id}`,
      data,
    };

    return axios.post(option);
  }

  createPatientDailyLog(text, id, day) {
    const option = {
      url: `${baseUrl}/createpatientdailylog`,
      data: { text, id, day },
    };

    return axios.post(option);
  }

  addKneeQs(main, question) {
    const option = {
      url: `${baseUrl}/knessQs`,
      data: { main, question },
    };

    return axios.post(option);
  }

  submitLogs(logs) {
    const option = {
      url: `${baseUrl}/postSurgeryLogs`,
      data: logs,
    };

    return axios.post(option);
  }
  uploadImage(data) {
    const option = {
      url: `${baseUrl}/uploadImage`,
      data,
    };
    console.log(option);
    return axios.post(option);
  }
  getPatientByCareId() {
    const option = {
      url: `${baseUrl}/patientdataByCare`,
    };

    return axios.get(option);
  }

  updatepatientActive(data) {
    const option = {
      url: `${baseUrl}/patientActive`,
      data,
    };

    return axios.put(option);
  }

  // uploadImage(formData) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`${baseUrl}/uplaodImage`, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
  //         },
  //       })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
}

const patientService = new PatientService();
export default patientService;
