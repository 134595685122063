import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
// mock
// import account from '../../../_mock/account';
// hooks
import loginService from "../../../services/loginService";
import useResponsive from "../../../hooks/useResponsive";
// components
//import Logo from '../../../components/logo';
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
// import navConfig from "./config";
import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const docNav = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "Patients",
    path: "/patients",
    icon: icon("ic_providers"),
  },
  {
    title: "Care Providers",
    path: "/care-providers",
    icon: icon("ic_careprovider"),
  },
  {
    title: "Device Configuration",
    path: "/deviceConfig",
    icon: icon("ic_devices"),
  },
];

const adminNav = [
  {
    title: "Providers",
    path: "/providers",
    icon: icon("ic_providers"),
  },
  {
    title: "Users",
    path: "/users",
    icon: icon("ic_users"),
  },
  {
    title: "Care Providers",
    path: "/care-providers",
    icon: icon("ic_careprovider"),
  },
  {
    title: "Patient Flow Configuration",
    path: "/flowConfig",
    icon: icon("ic_settings"),
  },
  {
    title: "Device Configuration",
    path: "/deviceConfig",
    icon: icon("ic_devices"),
  },
];

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [account, setAccount] = useState([]);
  const isDesktop = useResponsive("up", "lg");
  console.log("called nav");
  useEffect(() => {
    loadData();
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const loadData = () => {
    const data = loginService.getparamsFromCookie("jwt_session");
    if (data) {
      const accDetails = {};
      accDetails.username = loginService.getparamsFromCookie("username");
      accDetails.photoURL = loginService.getparamsFromCookie("avatar");
      accDetails.photoURL = loginService.getAvatar();

      setAccount(accDetails);
    }
  };
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {account.username}
              </Typography>

              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography> */}
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection
        data={loginService.getUserType() === "admin" ? adminNav : docNav}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
