import React from "react";

import trussLogo from "./logo_truss.jpeg";

const TrussLogo = ({ scale = 1 }) => {
  const scales = {
    1: { width: "44px" },
    2: { width: "88px" },
  };

  return (
    <div style={scales[scale]} className="truss-logo">
      <img style={{ width: "100%" }} src={trussLogo} alt="truss logo" />
    </div>
  );
};

export default TrussLogo;
