import React, { useState } from "react";
import imageIcon from "./icons/camera.svg";
import CameraComponent from "./CameraComponent";

const ImageUploader = ({ headtext, text, onImageUpload }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCamera, setCamera] = useState(false);

  const handleImageChange = (image) => {
    setSelectedImage(image);
    setCamera(false);
  };

  const handleUpload = () => {
    if (selectedImage) onImageUpload(selectedImage);
  };

  return (
    <>
      <div className="image-uploader">
        <div className="instruction">
          <h2>{headtext}</h2>
          <p>{text}</p>
        </div>

        <div
          className={`image-upload-label ${selectedImage ? "image" : ""}`}
          onClick={() => setCamera(true)}
        >
          {selectedImage ? (
            <img src={selectedImage} alt="Uploaded" className="image-preview" />
          ) : (
            <div className="camera-icon">
              <img src={imageIcon} alt="camera icon" />
              <span>Open Camera</span>
            </div>
          )}
        </div>

        <div className="action-btns">
          <button onClick={() => setSelectedImage(null)}>Cancel</button>
          <button onClick={handleUpload}>Upload</button>
        </div>
      </div>
      {isCamera && (
        <CameraComponent
          closeSelf={() => setCamera(false)}
          selectImage={handleImageChange}
        />
      )}
    </>
  );
};

export default ImageUploader;
