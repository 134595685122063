import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TrussLogo from "../truss-logo/TrussLogo";
import Question from "./components/Question";
import UserInput from "./components/UserInput";

import ArrowRightIcon from "./arrow-right.svg";
import careProviderService from "src/services/careProvidersService";

const TrussDoctorForm = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);

  const questions = [
    {
      ques: "Does the patient show any improvements post starting PT sessions(please select).",
      type: "single-select",
      options: [
        "Walk with lunch",
        "Can do daily activities",
        "Patient can sleep on his stomach without pain",
        "None of the above",
      ],
    },
    {
      ques: "Any changes in the pain scale?",
      type: "emotes",
      options: ["Excellent", "Good", "Medium", "Poor", "Bad"],
    },
    {
      ques: "Changes in observational postural assessment",
      type: "single-select",
      options: [
        "Walk with lunch",
        "Can do daily activities",
        "Patient can sleep on his stomach without pain",
        "None of the above",
      ],
    },
    {
      ques: "Range of motion",
      type: "text",
    },
    {
      ques: "Changes in functional assessment•",
      type: "single-select",
      options: [
        "Walk with lunch",
        "Can do daily activities",
        "Patient can sleep on his stomach without pain",
        "None of the above",
      ],
    },
    {
      ques: "Changes in balance:",
      type: "text",
    },
    {
      ques: "Differences in outcome assessment",
      type: "textarea",
    },
    {
      ques: "Gait",
      type: "single-select",
      options: ["100 meters with support", "50 meters with support"],
    },
    {
      ques: "Home Exercise Program Adherence :",
      type: "yes-no",
    },
  ];

  function nextQuestion() {
    if (questionNumber === questions.length - 1) {
      submitForm();
      return;
    }

    setQuestionNumber(Math.min(questionNumber + 1, questions.length - 1));
  }

  function prevQuestion() {
    setQuestionNumber(Math.max(questionNumber - 1, 0));
  }

  async function submitForm() {
    console.log("SUBMITTED", userAnswers);
    try {
      const data = await careProviderService.postPatientLogs(
        myProps?.id,
        userAnswers
      );
      console.log(data);
      navigate("/patientList", { replace: true });
    } catch (err) {
      console.log(err);
    }
    // After Submit redirect to some where
  }

  useEffect(() => {
    console.log(userAnswers);
  }, [userAnswers]);

  return (
    <div className="truss-form-container">
      <div className="logo-wrapper">
        <TrussLogo />
      </div>
      <div className="question-div">
        <div
          className="question-wrapper"
          style={{
            transform: `
            translateX(calc(${questionNumber * -80}px + ${
              -100 * questionNumber
            }%))`,
          }}
        >
          {questions.map((question, i) => {
            const { ques, type } = question;
            return (
              <div key={ques}>
                <Question number={i + 1} question={ques} />
                <UserInput
                  type={type} // input-type
                  number={i} // question-number
                  setUserAnswers={setUserAnswers}
                  questionData={question}
                  options={question.options}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="nav-btns">
        <button
          className={questionNumber ? "" : "disable"}
          type="button"
          onClick={prevQuestion}
        >
          <img src={ArrowRightIcon} alt="Icon" />
        </button>

        <button type="button" onClick={nextQuestion}>
          {questionNumber === questions.length - 1 ? (
            "Submit"
          ) : (
            <img src={ArrowRightIcon} alt="Icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default TrussDoctorForm;
