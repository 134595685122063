import React, { useState } from "react";
import ImageUploader from "src/components/imageUploader/ImageUploader";
import TrussLogo from "src/components/truss-logo/TrussLogo";

const UploadPhoto = () => {
  const handleImageUpload = async (image) => {
    const formData = new FormData();
    formData.append("image", image);

    try {
      const response = await fetch("YOUR_BACKEND_UPLOAD_ENDPOINT", {
        method: "POST",
        body: formData,
      });

      if (response.ok) console.log("Image uploaded successfully");
      else console.error("Image upload failed");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="image-upload-container">
      <TrussLogo />

      <div className="content">
        <h2>Upload your photo</h2>
        <p>Follow the instructions below:</p>

        <div className="image-uploaders">
          <ImageUploader
            headtext={"Angle 1 - Top"}
            text={
              "Please keep your best to keep your knee cap within the blue line"
            }
            onImageUpload={handleImageUpload}
          />

          <ImageUploader
            headtext={"Angle 2 - Side"}
            text={
              "Please keep your best to align your leg within the blue line"
            }
            onImageUpload={handleImageUpload}
          />
          <ImageUploader
            headtext={"Thermal Image"}
            text={
              "Please keep your best to align your leg within the blue line"
            }
            onImageUpload={handleImageUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadPhoto;
