import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactPhoneInput from "react-phone-input-material-ui";
import {
  Grid,
  Typography,
  Snackbar,
  FormControl,
  TextField,
} from "@mui/material";
import providerService from "src/services/providerService";
import { LoadingButton } from "@mui/lab";
import generateRandomPassword from "../helper/generatePassword";

const ProviderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    mobile: "+1",
    email: "",
    password: "",
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [mobileHelperText, setMobileHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  //passwordHelperText

  const isValidEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  // eslint-disable-next-line no-useless-escape

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleDropDown = (e) => {
  //   console.log(e);
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const checkPassword = (str) => {
    const reg =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+[\]{}|;:',.<>?])[A-Za-z!@#$%^&*()_+[\]{}|;:',.<>?0-9]{8,16}$/;
    console.log("text", reg.test(str));
    return reg.test(str);
  };
  const getPatientById = async (id) => {
    try {
      const data = await providerService.getProviderById(id);
      console.log("data", data);
      setFormData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (myProps?.id) {
      getPatientById(myProps.id);
    }
  }, [myProps]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setEmailHelperText("");
    setMobileHelperText("");
    setPasswordHelperText("");
    console.log("event", event?.target?.name);
    // console.log(event);
    if (event?.target?.name && event?.target?.name !== "password") {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } else if (event?.target?.name === "password") {
      const condition = checkPassword(event.target.value);
      if (condition === false) {
        setPasswordHelperText(
          "Password must between 8-16 characters long\n contains a number\n an uppercase letter,\n a lowercase letter\n a special character"
        );
      }
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } else {
      setFormData({ ...formData, mobile: event });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    setLoading(true);
    console.log(formData);
    try {
      if (formData?.email && !isValidEmail(formData?.email)) {
        setEmailHelperText("Invalid Email");
        setDisable(false);
        setLoading(false);
        return;
      } else if (formData?.mobile?.length < 10) {
        setMobileHelperText("Invalid Mobile");
        setDisable(false);
        setLoading(false);
        return;
      } else if (!myProps?.id && checkPassword(formData?.password) === false) {
        setPasswordHelperText(
          "Password must be 8 characters long\n contain a number\n an uppercase letter,\n a lowercase letter\n a special character"
        );
        setDisable(false);
        setLoading(false);
        return;
      }
      if (myProps?.id) {
        await providerService.updateProvider(formData);
      } else {
        await providerService.createProvider(formData);
      }
      navigate("/providers/list", { replace: true });
      setMsg("Patient added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      setLoading(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  const handleBack = () => {
    navigate("/providers/list", { replace: true });
  };

  const generatePassword = () => {
    const password = generateRandomPassword();
    setFormData({ ...formData, password: password });
  }
  return (
    <>
      <Helmet>
        <title> Add Provider | Truss Health </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Provider
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MuiTelInput value={formData.mobile} onChange={handleChange} /> */}
              <ReactPhoneInput
                error={mobileHelperText ? true : false}
                value={formData.mobile}
                defaultCountry={"gb"}
                onChange={handleChange}
                // inputClass={classes.field}
                // dropdownClass={classes.countryList}
                component={TextField}
                helperText={mobileHelperText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                error={emailHelperText ? true : false}
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                helperText={emailHelperText}
              />
            </Grid>
            {myProps?.id ? (
              <>
              <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required={myProps?.id ? false : true}
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    disabled={myProps?.id ? true : false}
                  />
                </Grid>
                <Grid className="gen_password" item xs={12} sm={6}>
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    onClick={generatePassword}
                    //onClick={handleClick}
                  >
                    Generate Password
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6}>
                <TextField
                  required={myProps?.id ? false : true}
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  hidden={myProps?.id ? true : false}
                  helperText={passwordHelperText}
                />
              </Grid>
            )}
            {myProps?.id ? (
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Update
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              // <Grid className="submit_button" item xs={12} sm={6}>
              //   <Button
              //     disabled={disable}
              //     variant="contained"
              //     color="primary"
              //     type="submit"
              //   >
              //     Update
              //   </Button>
              // </Grid>
              <>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    disable={disable}
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    //onClick={handleClick}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid className="submit_button" item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </LoadingButton>
                </Grid>
              </>
              // <Grid className="submit_button" item xs={12} sm={6}>
              //   <Button
              //     disabled={disable}
              //     variant="contained"
              //     color="primary"
              //     type="submit"
              //   >
              //     Submit
              //   </Button>
              // </Grid>
            )}
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default ProviderForm;
