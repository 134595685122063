import React, { useEffect, useState } from "react";

const TextInput = ({ handleChange }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    handleChange(value ? value : "");
  }, [value]);

  return (
    <div className="text-select-input">
      <input
        type="number"
        placeholder="Your input"
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default TextInput;
