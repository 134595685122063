import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import MobileLayout from "../layouts/dashboard/MobileLayout";
import patientService from "src/services/patientService";
import "./common.css";

export default function PatientList() {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    getPatientList();
  }, []);

  const getPatientList = async () => {
    try {
      const data = await patientService.getPatientByCareId();
      setDisable(false);
      console.log(data);
      setPatientList(data);
    } catch (err) {
      console.log(err);
      setDisable(false);
    }
  };

  const onItemClick = (e) => {
    e.preventDefault();
    const id = e.target?.id;
    setSelectedPatient(id);
    navigate("/doctor-form", {
      state: {
        id,
      },
      replace: true,
    });
  };
  return (
    <>
      <MobileLayout userType="careprovider" />
      <div className="guidance-container patient_list_new patient_list">
        <div className="scrollable-list">
          <h2>Patients List</h2>
          <div className="list-container">
            <Fade
              in={disable}
              style={{
                transitionDelay: disable ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <ul>
              {patientList.map((item, index) => (
                <li
                  type="button"
                  key={item.id}
                  id={item.id}
                  onClick={onItemClick}
                  className={item.id === selectedPatient ? "selected" : ""}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
