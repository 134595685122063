import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./common.css";
import loginService from "src/services/loginService";
import {
  IconButton,
  InputAdornment,
  CssBaseline,
  Button,
  Avatar,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Snackbar,
  Grid,
} from "@mui/material";
import Iconify from "../components/iconify";
import { LoadingButton } from "@mui/lab";
//import Logo from "../assets/images/logo1.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://trusshealth.ai/">
        Truss Health
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      (v1.6)
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function CareLoginPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      setDisable(true);
      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      const password = data.get("password");
      const userType = "careprovider";
      console.log({
        email: data.get("email"),
        password: data.get("password"),
      });
      const loginData = await loginService.loginUser(email, password, userType);
      loginService.doLogin(loginData?.data?.token);
      console.log("loginData", loginData);
      navigate("/patientList", { replace: true });
    } catch (err) {
      setLoading(false);
      setDisable(false);
      setMsg("Login Failed! Please check your credentials and try again!");
      setOpen(true);
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Truss Health"
            className="patient_login_logo"
            src={require("../components/logo/logo2.jpeg")}
          />
          {/* <LockOutlinedIcon /> */}

          {/* <Card sx={{ minWidth: 275 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={Logo}
              title="green iguana"
            />
          </Card> */}
          <Typography component="h1" variant="h6" color={"black"}>
            Welcome to Truss Health!
          </Typography>
          {/* <Typography component="h1" variant="h6" color={"grey"}>
            Login with your given Login Id and Password
          </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Login Id"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              name="password"
              label="Password"
              id="password"
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              // onChange={textInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <LoadingButton
              fullWidth
              disable={disable}
              size="large"
              type="submit"
              loading={loading}
              variant="contained"
              // onClick={handleClick}
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
